import { LeftOutlined, RightOutlined } from "@ant-design/icons"

// Custom arrow components
export const PrevArrow = ({ onClick }) => (
  <div
    className="absolute border-1 border-#fff bottom-8 left-8 text-white rounded-full p-[10px] cursor-pointer z-50"
    style={{ fontSize: "14px", lineHeight: "1" }}
    onClick={onClick}>
    <LeftOutlined />
  </div>
)

export const NextArrow = ({ onClick }) => (
  <div
    className="absolute border-1 border-#fff bottom-8 left-20 text-white rounded-full p-[10px] cursor-pointer z-50"
    style={{ fontSize: "14px", lineHeight: "1" }}
    onClick={onClick}>
    <RightOutlined />
  </div>
)
