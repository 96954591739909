import { Button, Modal } from "antd"
import { useSelector } from "react-redux"
import signupConfirmDr from "../../assets/signup-confirm-doctor.png"
import React from "react"

const ConfirmSignup = ({ onConfirmSignup, showConfirmSignup }) => {
  const { loading, error } = useSelector(state => state.confirmSignup)

  return (
    <Modal
      width={550}
      footer={null}
      closeIcon={null}
      modalRender={modal => {
        return React.cloneElement(modal, {
          style: {
            ...modal.props.style,
            ...{ borderRadius: "8px", padding: "12px", marginTop: "-20px" }
          }
        })
      }}
      open={showConfirmSignup}>
      <div className="flex flex-col items-center justify-center text-center">
        <img src={signupConfirmDr} alt="simling-doctor" className="h-90vh w-full rounded-md" />
        <h2 className="text-lg font-semibold my-3">Welcome to FitPeo</h2>
        <div className="sm:px-28 md:px-30">
          <p className="mb-4 text-[#979797]">
            As you are a new user, would you like to Sign Up for your account?
          </p>
          {error !== undefined && typeof error === "string" && (
            <p className="text-danger text-center font-medium mb-2">{error}</p>
          )}
          <Button
            size="large"
            type="primary"
            block
            className="mb-3"
            onClick={onConfirmSignup}
            loading={loading}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmSignup
