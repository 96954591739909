import { Row, Col, Select, Input } from "antd"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import * as yup from "yup"
import "react-datepicker/dist/react-datepicker.css"
import { useDispatch, useSelector } from "react-redux"
import {
  createPatientVitals,
  getPatientVitals,
  postPatientVitals
} from "../../../../redux/actions/patientAction"
import { useEffect } from "react"
import Loading from "../../../layouts/Loading/Loading"
import FloatInput from "../../../../utils/FloatInput/FloatInput"
import StepsFooter from "./StepsFooter"

const MIN_WEIGHT_ERR = "Weight must be more than 10 lbs"
const MAX_WEIGHT_ERR = "Weight must be less than 900 lbs"

const schema = yup.object().shape({
  heightIN: yup
    .number()
    .min(0, "Height (In.) must be greater than or equal to 0")
    .max(11, "Height (In.) must be less than or equal to 11")
    .transform(value => (Number.isNaN(value) ? null : value)),
  heightFT: yup.number().transform(value => (Number.isNaN(value) ? null : value)),
  weight: yup
    .number()
    .transform((value, originalValue) => {
      // Convert empty values to null
      if (originalValue === "" || Number.isNaN(value)) {
        return null
      }
      return value
    })
    .nullable()
    .min(10, MIN_WEIGHT_ERR)
    .max(900, MAX_WEIGHT_ERR)
})

const VitalDetailWeb = ({ formData, setFormData, setCurrentStep, handleBack }) => {
  const dispatch = useDispatch()
  const { loading, error } = useSelector(state => state.postPatientVitals)
  const { patientVitals, loading: vitalsLoading } = useSelector(state => state.patientVitals)

  const defaultValues = {
    heightFT: parseInt(patientVitals?.find(item => item.vitalType === "height")?.value / 12) || 0,
    heightIN: parseInt(patientVitals?.find(item => item.vitalType === "height")?.value % 12) || 0,
    weight: patientVitals?.find(item => item.vitalType === "weight")?.value || ""
  }

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues,
    shouldFocusError: false,
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    setValue(
      "heightFT",
      parseInt(patientVitals?.find(item => item.vitalType === "height")?.value / 12) || 0
    )
    setValue(
      "heightIN",
      parseInt(patientVitals?.find(item => item.vitalType === "height")?.value % 12) || 0
    )
    setValue("weight", patientVitals?.find(item => item.vitalType === "weight")?.value || "")
  }, [patientVitals])

  useEffect(() => {
    dispatch(getPatientVitals(formData?.id))
  }, [dispatch, formData?.id])

  const watchHeightFT = watch("heightFT")
  const watchWeight = watch("weight")

  const onSubmit = async data => {
    if (!data?.heightFT || !data?.weight) {
      setCurrentStep(prevStep => prevStep + 1)
      return
    }
    try {
      if (patientVitals && patientVitals.length) {
        // Post weight data
        const weightObj = { ...patientVitals?.find(v => v.vitalType === "weight") }
        const weightResponse = await dispatch(
          postPatientVitals(formData?.id, weightObj?.id, {
            unit: weightObj.unit,
            value: data.weight,
            vitalType: "weight"
          })
        )

        if (weightResponse?.message?.toLowerCase() !== "success") {
          throw new Error("Failed to post weight data")
        }

        // Post height data
        const heightObj = { ...patientVitals?.find(v => v.vitalType === "height") }
        const heightResponse = await dispatch(
          postPatientVitals(formData?.id, heightObj?.id, {
            unit: heightObj.unit,
            value: data.heightFT * 12 + data.heightIN,
            vitalType: "height"
          })
        )

        if (heightResponse?.message?.toLowerCase() === "success") {
          setCurrentStep(prevStep => prevStep + 1)
        } else {
          throw new Error("Failed to post height data")
        }
      } else {
        const createVitalResponse = await dispatch(
          createPatientVitals(formData?.id, [
            {
              unit: "lb",
              value: data.weight.toFixed(1),
              vitalType: "weight"
            },
            {
              vitalType: "height",
              unit: "inch",
              value: parseInt(parseInt(data.heightFT * 12) + parseInt(data.heightIN))
            }
          ])
        )

        if (createVitalResponse?.message?.toLowerCase() === "success") {
          setCurrentStep(prevStep => prevStep + 1)
        } else {
          throw new Error("Failed to post vital data")
        }
      }
    } catch (error) {
      console.error("Error submitting vitals:", error.message)
      // Handle error (e.g., show an error message to the user)
    }
  }

  if (vitalsLoading) {
    return <Loading />
  }

  // Generates height options from 2 feet to 8 feet
  const heightOptions = Array.from({ length: 8 - 2 + 1 }, (_, i) => i + 2).map(value => ({
    label: `${value} ft`,
    value
  }))

  const inchesOptions = Array.from({ length: 12 }, (_, i) => i).map(value => ({
    label: `${value} in`,
    value
  }))

  return (
    <>
      <h1>Vital Details</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-6">
        <Row gutter={20}>
          <Col xs={24} md={12} className="mb-4">
            <Controller
              name="heightFT"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FloatInput label="Height (ft)">
                  <Select
                    {...field}
                    options={heightOptions}
                    onChange={value => {
                      field.onChange(value)
                      setFormData(prevData => ({
                        ...prevData,
                        heightFT: value
                      }))
                    }}
                    status={errors.heightFT ? "error" : undefined}
                    style={{ width: "100%" }}
                  />
                </FloatInput>
              )}
            />
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <Controller
              name="heightIN"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FloatInput label="Height (in)">
                  <Select
                    {...field}
                    options={inchesOptions}
                    onChange={value => {
                      field.onChange(value)
                      setFormData(prevData => ({
                        ...prevData,
                        heightIN: value
                      }))
                    }}
                    status={errors.heightIN ? "error" : undefined}
                    style={{ width: "100%" }}
                  />
                </FloatInput>
              )}
            />
          </Col>
          {errors.heightFT || errors.heightIN ? (
            <small className="text-danger">
              {errors.heightFT?.message || errors.heightIN?.message}
            </small>
          ) : null}

          <Col xs={24} md={24} className="mb-4">
            <Controller
              name="weight"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FloatInput label="Weight (lbs)">
                  <Input
                    type="number"
                    max={900}
                    value={field.value}
                    onChange={e => {
                      const value = e.target.value
                      const validatedValue = value.match(/^\d{0,3}\.?\d{0,2}/)?.[0] || ""
                      field.onChange(validatedValue)
                      setFormData(prevData => ({
                        ...prevData,
                        weight: validatedValue
                      }))
                    }}
                    status={errors.weight ? "error" : undefined}
                  />
                </FloatInput>
              )}
            />
            {errors.weight ? <small className="text-danger">{errors.weight.message}</small> : null}
          </Col>
          {error ? (
            <Col className="mb-4" xs={24}>
              <p className="text-danger font-semibold">{error}</p>
            </Col>
          ) : null}
        </Row>
        <StepsFooter
          handleBack={handleBack}
          nextBtnLoading={loading}
          nextBtnText={watchHeightFT && watchWeight ? "Next" : "Skip"}
        />
      </form>
    </>
  )
}

export default VitalDetailWeb
