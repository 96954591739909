import { Col, Spin } from "antd"
import weightScaleImg from "../../../../assets/weight-scale.png"
import glucoMonitorImg from "../../../../assets/glucose-monitor.png"
import bpMonitorImg from "../../../../assets/bp-monitor.png"
import pulseOxiImg from "../../../../assets/pulse-oximeter.png"
import { useEffect, useState } from "react"
import { getPatientMonitoringData } from "../../../../redux/actions/patientAction"
import { useDispatch, useSelector } from "react-redux"
import { getrpmDeviceActions } from "../../../../redux/actions/rpmAction"
import { updatePatientWelcomeTutorial } from "../../../../redux/actions/selfOnboardPatientAction"
import { useNavigate } from "react-router-dom"
import Loading from "../../../layouts/Loading/Loading"
import { getEquipmentStatusAction } from "../../../../redux/actions/equipmentStatusAction"
import { EquipmentSatusOptions } from "../../Types"
import EnrollmentSplCodeModal from "../../EnrollmentSplCodeModal"
import StepsFooter from "./StepsFooter"

const SuggestedDevicesWeb = ({ patientId, handleBack }) => {
  patientId = JSON.parse(localStorage.getItem("id"))
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showWm, setShowWm] = useState(false)
  const [showBp, setShowBp] = useState(false)
  const [showOxi, setShowOxi] = useState(false)
  const [showGluco, setShowGluco] = useState(false)
  const [isCodeModalVisible, setIsCodeModalVisible] = useState(false)

  const monitorTypes = JSON.parse(localStorage.getItem("monitorTypes"))

  const { patientMonitoringData, loading } = useSelector(state => state.patientMonitoringData)
  const { loading: updateWelcomeLoading, error: updateWelcomeError } = useSelector(
    state => state.updateSelfOnboaredePatient
  )
  const { loading: rpmDeviceLoading, rpmDevices } = useSelector(state => state.rpmDevices)
  const {
    getEquipmentStatus,
    loading: equipmentStatusLoading,
    error: equipmentStatusError
  } = useSelector(state => state.getEquipmentStatus)
  const { shippingAddress, loading: shippingFetchLoading } = useSelector(
    state => state.shippingAddress
  )
  const { loading: patientLoading, userInfo: patientInfoDetail } = useSelector(
    state => state.userInfo
  )
  useEffect(() => {
    if (!patientId) return
    dispatch(getrpmDeviceActions())
    dispatch(getEquipmentStatusAction(patientId))
  }, [patientId])

  useEffect(() => {
    const programDevice = rpmDevices?.find(({ name }) => name.toLowerCase() === "rpm")?.programs
    if (patientMonitoringData && patientMonitoringData?.rpm && monitorTypes?.length) {
      patientMonitoringData?.rpm?.map(({ programs }) =>
        programs?.map(({ programId, programName }) => {
          if (
            programDevice?.find(
              ({ id, name }) => id === programId && name.toLowerCase() === programName.toLowerCase()
            )
          ) {
            if (programName.toLowerCase() === "weight machine") setShowWm(true)
            else if (programName.toLowerCase() === "blood pressure machine") setShowBp(true)
            else if (programName.toLowerCase() === "oximeter") setShowOxi(true)
            else if (programName.toLowerCase() === "glucose machine") setShowGluco(true)
          }
        })
      )
    } else {
      setShowWm(false)
      setShowBp(false)
      setShowOxi(false)
      setShowGluco(false)
    }
  }, [loading, rpmDeviceLoading])

  const isDone = async () => {
    try {
      const result = await dispatch(updatePatientWelcomeTutorial(patientId))
      if (result?.status === 200) {
        setIsCodeModalVisible(true)
      } else {
        console.error("Failed to update patient welcome tutorial. Status code:", result?.status)
      }
    } catch (error) {
      // Handle any errors here
      console.error("An error occurred:", error)
    }
  }

  return (
    <>
      {!loading && !rpmDeviceLoading ? (
        <div className="pt-8 px-3 text-center flex flex-col">
          <div className="flex-1">
            <div className="w-[600px] m-auto">
              <p className="font-bold">
                Your provider has recommended these device(s) for you at no cost to enhance your
                health monitoring.
              </p>

              {!showGluco && !showBp && !showOxi && !showWm ? (
                <h2 className="mt-2">No device(s) selected </h2>
              ) : null}
              <div className="w-[400px] m-auto mt-3 text-[#232D4C]">
                {!equipmentStatusLoading ? (
                  (showGluco || showBp || showOxi || showWm) &&
                  patientInfoDetail?.userType !== "facility" ? ( // dont show the device pick info for the "no devices and facility patient"
                    EquipmentSatusOptions[1].label.toLowerCase().trim() ===
                    getEquipmentStatus?.status?.toLowerCase()?.trim() ? (
                      <span>
                        You can collect your device(s) from the clinic. We will inform you once the
                        device(s) are ready to collect.
                      </span>
                    ) : (
                      <span>
                        Your device(s) will be shipped to this address :
                        <span className="block capitalize">
                          {`${shippingAddress?.street} ${
                            shippingAddress?.flatNo ? `#${shippingAddress?.flatNo}` : ""
                          }, ${shippingAddress?.city}, ${shippingAddress?.state}, ${
                            shippingAddress?.zipcode
                          }`}
                        </span>
                      </span>
                    )
                  ) : (
                    <></>
                  )
                ) : (
                  <Spin />
                )}
              </div>
            </div>
            <div className="my-4 flex gap-10 justify-center flex-wrap">
              {showWm ? (
                <div className="device-card">
                  <img src={weightScaleImg} alt="Weight Scale" />
                  <h2>Weight Scale</h2>
                </div>
              ) : null}
              {showOxi ? (
                <div className="device-card">
                  <img src={pulseOxiImg} alt="Pulse Oximeter" />
                  <h2>Pulse Oximeter</h2>
                </div>
              ) : null}
              {showBp ? (
                <div className="device-card">
                  <img src={bpMonitorImg} alt="Blood Pressure Machine" />
                  <h2>BP Monitor</h2>
                </div>
              ) : null}
              {showGluco ? (
                <div className="device-card">
                  <img src={glucoMonitorImg} alt="Blood Glucose Machine" />
                  <h2>Glucose Monitor</h2>
                </div>
              ) : null}
            </div>

            {updateWelcomeError ? (
              <Col className="mb-4" xs={24}>
                <p className="text-danger font-semibold">{updateWelcomeError}</p>
              </Col>
            ) : null}
          </div>

          <StepsFooter
            handleBack={handleBack}
            handleNext={isDone}
            nextBtnLoading={updateWelcomeLoading}
          />
        </div>
      ) : (
        <Loading />
      )}
      {isCodeModalVisible ? (
        <EnrollmentSplCodeModal
          isCodeModalVisible={isCodeModalVisible}
          setIsCodeModalVisible={setIsCodeModalVisible}
          patientId={patientId}
        />
      ) : null}
    </>
  )
}

export default SuggestedDevicesWeb
