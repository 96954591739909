import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons"
import { Button } from "antd"
import React from "react"

const StepsFooter = ({ handleNext, handleBack, nextBtnLoading, nextBtnDisable, nextBtnText }) => {
  return (
    <div
      style={{
        height: 60,
        zIndex: 999,
        position: "fixed",
        bottom: 0,
        left: "50%",
        width: "80%",
        transform: "translateX(-50%)"
      }}
      className="bg-[#fff] flex gap-4 justify-center items-center">
      <Button
        className="w-[200px]"
        type="link border-primary"
        icon={<ArrowLeftOutlined />}
        onClick={handleBack}>
        Back
      </Button>

      <Button
        className="w-[200px]"
        type="primary"
        htmlType="submit"
        disabled={nextBtnDisable}
        onClick={handleNext}
        loading={nextBtnLoading}>
        {nextBtnText ? nextBtnText : "Next"}
        <ArrowRightOutlined style={{ display: "inline-flex" }} />
      </Button>
    </div>
  )
}

export default StepsFooter
