import React, { useEffect, useState } from "react"
import { Row, Col, Button, Select, Input, DatePicker } from "antd"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import * as yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import {
  UpdatePatientAction,
  getOutPatientAddressAction,
  postOutPatientAddressAction
} from "../../../redux/actions/patientAction"
import { Helmet } from "react-helmet"
import { PatternFormat } from "react-number-format"
import formatPhoneNumber from "../../../utils/formatPhoneNumber"
import GoogleMapUseAuto from "../GoogleMapUseAuto"
import fields from "../../../utils/addressFields"
import AddressFormField from "../../../utils/AddressFormField"

const emailRegex = /^[A-Za-z0-9._]{3,}@[a-zA-Z]{3,}[.]{1,1}[a-zA-Z.]{2,6}$/

const alphaRegex = /^[a-zA-Z\s]+$/

const INVALID_DOB_ERR = "Invalid DOB"
const INVALID_GENDER_ERR = "Invalid Gender"
const INVALID_MIDDLE_NAME_ERR = "Middle Name should only contain alphabets"
const INVALID_LAST_NAME_ERR = "Invalid Last Name"
const LAST_NAME_ALPHABETS_ONLY_ERR = "Last Name should contain alphabets only"
const FIRST_NAME_ALPHABETS_ONLY_ERR = "First Name should contain alphabets only"
const INVALID_FIRST_NAME_ERR = "Invalid First Name"
const INVALID_EMAIL_ERR = "Invalid email address"
const EMAIL_OR_MOBILE_REQUIRED_MSG = "Enter either Email or mobileNumber"
const MOBILE_NUMBER_VALIDATION_ERR = "Mobile Number should be of 10 digits"
const INVALID_LANDLINE_ERR = "Invalid Landline Number"
const TELE_EXTENTION_MAX_ERR = "Telephone Extension must be at most 5 characters"
const TELE_EXTENTION_MIN_ERR = "Telephone Extension must be at least 1 characters"
const INVALID_TELE_EXTENTION_ERR = "Invalid Extension"
const INVALID_HEALTHCARE_PROVIDER_ERR = "Invalid Healthcare Provider"
const INVALID_ADDRESS_ERR = "Primary address is required"
const INVALID_STREET_ERR = "Street is required"
const INVALID_CITY_ERR = "City is required"
const INVALID_STATE_ERR = "State is required"
const INVALID_ZIP_ERR = "Zip code is required"

const schema = yup
.object()
.shape(
  {
    dob: yup.string().required(INVALID_DOB_ERR),
    gender: yup.string().required(INVALID_GENDER_ERR),
    userType: yup.string(),
    middleName: yup
      .string()
      .max(100)
      .matches(/^[a-zA-Z\s]*$/, INVALID_MIDDLE_NAME_ERR),
    lastName: yup
      .string()
      .trim()
      .min(1)
      .max(100)
      .matches(alphaRegex, LAST_NAME_ALPHABETS_ONLY_ERR)
      .required(INVALID_LAST_NAME_ERR),
    firstName: yup
      .string()
      .trim()
      .min(1)
      .max(100)
      .matches(alphaRegex, FIRST_NAME_ALPHABETS_ONLY_ERR)
      .required(INVALID_FIRST_NAME_ERR),
    email: yup
      .string()
      .matches(emailRegex, INVALID_EMAIL_ERR)
      .when("mobileNumber", {
        is: value => !value || value.length === 0,
        then: () =>
          yup
            .string()
            .matches(emailRegex, INVALID_EMAIL_ERR)
            .required(EMAIL_OR_MOBILE_REQUIRED_MSG),
        otherwise: () =>
          yup.string().test("checkEmail", "", (value, { createError, path }) => {
            if (value.length && !emailRegex.test(value)) {
              return createError({
                path,
                message: INVALID_EMAIL_ERR
              })
            } else {
              return true
            }
          })
      }),
    mobileNumber: yup
      .string()
      .min(10)
      .max(10)
      .when("email", {
        is: value => !value || value.length === 0,
        then: () => yup.string().required(EMAIL_OR_MOBILE_REQUIRED_MSG),
        otherwise: () =>
          yup.string().test("mobileNumber", (value, { createError, path }) => {
            if (value.length && value.replace(/[^\d]/g, "").length !== 10) {
              return createError({
                path,
                message: MOBILE_NUMBER_VALIDATION_ERR
              })
            } else {
              return true
            }
          })
      }),
    healthcareProvider: yup
      .string()
      .trim()
      .min(1)
      .max(100)
      .required(INVALID_HEALTHCARE_PROVIDER_ERR),
    street: yup.string().when("userType", {
      is: value => value?.toLowerCase() === "outpatient",
      then: () => yup.string().required(INVALID_STREET_ERR),
      otherwise: () => yup.string().notRequired()
    }),
    city: yup.string().when("userType", {
      is: value => value?.toLowerCase() === "outpatient",
      then: () => yup.string().required(INVALID_CITY_ERR),
      otherwise: () => yup.string().notRequired()
    }),
    state: yup.string().when("userType", {
      is: value => value?.toLowerCase() === "outpatient",
      then: () => yup.string().required(INVALID_STATE_ERR),
      otherwise: () => yup.string().notRequired()
    }),
    zipcode: yup.string().when("userType", {
      is: value => value?.toLowerCase() === "outpatient",
      then: () => yup.string().required(INVALID_ZIP_ERR),
      otherwise: () => yup.string().notRequired()
    }),
    apartmentNumber: yup.string().max(50).notRequired(),
    telephone: yup.string().when({
      is: value => value && value.length > 0,
      then: () =>
        yup
          .string()
          .matches(/^\(\d{3}\) \d{3}-\d{4}$/, INVALID_LANDLINE_ERR)
          .required(INVALID_LANDLINE_ERR)
    }),
    teleExtension: yup.string().when("telephone", {
      is: value => value && value.length > 0,
      then: () =>
        yup
          .string()
          .min(1, TELE_EXTENTION_MIN_ERR)
          .max(5, TELE_EXTENTION_MAX_ERR)
          .required(INVALID_TELE_EXTENTION_ERR)
    })
  },
  ["email", "mobileNumber"]
)
.required()

const PersonalDetailForm = ({ formData, setFormData, setCurrentStep, prevPage }) => {
  const defaultValues = {
    userType: formData?.userType || "",
    email: formData?.email || "",
    gender: formData?.gender || "",
    lastName: formData?.lastName || "",
    firstName: formData?.firstName || "",
    telephone: formatPhoneNumber(formData?.telephone) || "",
    teleExtension: formData?.extension || "",
    middleName: formData?.middleName || "",
    dob: moment(formData?.dob).format("MM/DD/YYYY") ?? "",
    mobileNumber: formatPhoneNumber(formData?.mobileNumber) || "",
    healthcareProvider: formData?.orgName || ""
  }
  const dispatch = useDispatch()
  const { loading, error } = useSelector(state => state.updatePatient)
  const { loading: patientLoading, userInfo: patientInfoDetail } = useSelector(
    state => state.userInfo
  )
  const { error: postAddressError, loading: postAddressLoading } = useSelector(
    state => state.postOutpatientAddress
  )
  const { outpatientAddress, loading: fetchAddressLoading } = useSelector(
    state => state.outpatientAddress
  )
  const [isFocused, setIsFocused] = useState(false)
  const [isTelephoneFocused, setIsTelephoneFocused] = useState(false)
  const [address, setAddress] = useState()

  const {
    reset,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    shouldFocusError: false,
    resolver: yupResolver(schema)
  })
  useEffect(() => {
    dispatch(getOutPatientAddressAction(formData.id))
  }, [dispatch, formData.id])

  useEffect(() => {
    setAddress(outpatientAddress)
  }, [fetchAddressLoading])
  
  useEffect(() => {
    setValue("apartmentNumber", address?.flatNo ? address?.flatNo : null)
    setValue("street", address?.street)
    setValue("city", address?.city)
    setValue("state", address?.state)
    setValue("zipcode", address?.zipcode)
  }, [address])

  const onSubmit = async data => {
    const {
      dob,
      email,
      gender,
      userName,
      lastName,
      firstName,
      middleName,
      telephone,
      teleExtension,
      mobileNumber,
      userType,
      primaryAddress,
      street,
      city,
      state,
      zipcode,
      apartmentNumber
    } = data

    const result = dispatch(
      UpdatePatientAction({
        gender,
        lastName,
        firstName,
        id: formData.id,
        dob: moment(dob).format(),
        countryCode: formData?.countryCode.toUpperCase(),
        ...(middleName.length
          ? {
              middleName
            }
          : {
              middleName: null
            }),
        ...(userName?.length ? { userName } : { userName: `${firstName}${middleName}${lastName}` }),
        ...(telephone.length
          ? { telephone: telephone?.replace(/[^\d]/g, ""), extension: teleExtension }
          : {}),
        ...(email && email?.length ? { email } : {}),
        ...(mobileNumber?.length ? { mobileNumber: mobileNumber?.replace(/[^\d]/g, "") } : {})
      })
    )
    result &&
      result?.then(res => {
        if (res?.status === 200 || res?.status === 201) {
          if (userType !== "facility") {
            const result = dispatch(
              postOutPatientAddressAction(formData.id, {
                street,
                state,
                flatNo: apartmentNumber?.length ? apartmentNumber : null,
                zipcode,
                city,
                id: address?.id
              })
            )
            result.then(res => {
              if (res?.message?.toLowerCase() === "success") {
                setCurrentStep(prevStep => prevStep + 1)
                dispatch(getOutPatientAddressAction(formData.id))
              }
            })
          } else {
            setCurrentStep(prevStep => prevStep + 1)
          }
        }
      })
  }

  window.back = () => {
    prevPage()
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const handleTelephoneFocus = () => {
    setIsTelephoneFocused(true)
  }

  const handleTelephoneBlur = () => {
    setIsTelephoneFocused(false)
  }

  const handleMobileChange = e => {
    const { value } = e.target
    return formatPhoneNumber(value)
  }
  return (
    <>
      <h1 className={`${!window?.isMobileVersion ? "pb-1" : "pb-8"} pt-4 text-center`}>
        Please confirm the details
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={20}>
          <Col xs={24} md={12} className="mb-4">
            <label className="text-[#959595] mb-[0.75rem] text-base" htmlFor="input-firstName">
              First Name <span className="text-danger">*</span>
            </label>
            <Controller
              name="firstName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  id="input-firstName"
                  className="capitalize"
                  status={errors.firstName ? "error" : undefined}
                  {...field}
                  disabled
                />
              )}
            />
            {errors.firstName ? (
              <small className="text-danger">{errors.firstName.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="text-[#959595] mb-[0.75rem] text-base" htmlFor="input-middleName">
              Middle Name
            </label>
            <Controller
              name="middleName"
              control={control}
              render={({ field }) => (
                <Input
                  id="input-middleName"
                  className="capitalize"
                  status={errors.middleName ? "error" : undefined}
                  {...field}
                  disabled
                />
              )}
            />
            {errors.middleName ? (
              <small className="text-danger">{errors.middleName.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="text-[#959595] mb-[0.75rem] text-base" htmlFor="input-lastName">
              Last Name <span className="text-danger">*</span>
            </label>
            <Controller
              name="lastName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  id="input-lastName"
                  className="capitalize"
                  status={errors.lastName ? "error" : undefined}
                  {...field}
                  disabled
                />
              )}
            />
            {errors.lastName ? (
              <small className="text-danger">{errors.lastName.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="text-[#959595] mb-[0.75rem] text-base" htmlFor="input-dob">
              Date Of Birth <span className="text-danger">*</span>
            </label>
            <Controller
              name="dob"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DatePicker
                  required
                  selected={field.value}
                  value={field.value ? moment(field.value) : null}
                  onChange={(date, dateString) => field.onChange(dateString)}
                  format="MM/DD/YYYY"
                  disabledDate={current => current && current > moment().endOf("day")}
                  placeholder="Date of Birth"
                  className=" bg-[#f5f5f5] border p-3 rounded-[12px] w-100 h-[48px]"
                  disabled
                />
              )}
            />
            {errors.dob ? <small className="text-danger">{errors.dob.message}</small> : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="text-[#959595] mb-[0.75rem] text-base" htmlFor="input-gender">
              Gender at time of Birth<span className="text-danger">*</span>
            </label>
            <Controller
              name="gender"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  className="w-100"
                  status={errors.gender ? "error" : undefined}
                  options={[
                    {
                      value: "male",
                      label: "Male"
                    },
                    {
                      value: "female",
                      label: "Female"
                    }
                  ]}
                  {...field}
                  disabled
                />
              )}
            />
            {errors.gender ? (
              <small className="text-danger block">{errors.gender.message}</small>
            ) : null}
          </Col>

          <Col xs={24} md={12} className="mb-4">
            <label className="text-[#959595] mb-[0.75rem] text-base" htmlFor="input-email">
              Email
              {patientInfoDetail?.email?.length ? <span className="text-danger">*</span> : null}
            </label>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  type="email"
                  id="input-email"
                  placeholder="Enter Email"
                  className="bg-[#fff]"
                  status={errors.email ? "error" : undefined}
                  value={field.value} // Use field.value from react-hook-form
                  onChange={e => {
                    field.onChange(e) // Notify react-hook-form of the change
                    setFormData(prevData => ({
                      ...prevData,
                      email: e.target.value // Update the corresponding field in formData
                    }))
                  }}
                  disabled={
                    !patientInfoDetail?.mobileNumber?.length ||
                    (patientInfoDetail?.email?.length && patientInfoDetail?.mobileNumber?.length)
                  }
                />
              )}
            />
            {errors.email ? <small className="text-danger">{errors.email.message}</small> : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="text-[#959595] mb-[0.75rem] text-base" htmlFor="input-mobileNumber">
              Mobile Number{" "}
              {patientInfoDetail?.mobileNumber?.length ? (
                <span className="text-danger">*</span>
              ) : null}
            </label>
            <Controller
              name="mobileNumber"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                const { ref, ...rest } = field
                return (
                  <PatternFormat
                    format="(###) ###-####"
                    className={`formatted-mobile-input flex px-[11px] py-[12px] w-full  rounded-xl border border-${
                      errors.mobileNumber ? "danger" : isFocused ? "primary" : "#dfdfdf"
                    } focus:outline-none ${
                      !patientInfoDetail?.email?.length ||
                      (patientInfoDetail?.email?.length && patientInfoDetail?.mobileNumber?.length)
                        ? "cursor-not-allowed text-[#b7b7bf] bg-[#f5f5f5]"
                        : "bg-[#fff] "
                    }`}
                    id="input-mobileNumber"
                    {...rest}
                    onChange={e => rest.onChange(handleMobileChange(e))}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="Enter Mobile Number"
                    disabled={
                      !patientInfoDetail?.email?.length ||
                      (patientInfoDetail?.email?.length && patientInfoDetail?.mobileNumber?.length)
                    }
                  />
                )
              }}
            />
            {errors.mobileNumber ? (
              <small className="text-danger">{errors.mobileNumber.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="text-[#959595] mb-[0.75rem] text-base" htmlFor="input-teleExtension">
              Extension
            </label>
            <Controller
              name="teleExtension"
              control={control}
              render={({ field }) => (
                <Input
                  type="number"
                  className="bg-[#fff]"
                  placeholder="Enter Extension"
                  maxLength={5}
                  id="input-teleExtension"
                  status={errors.teleExtension ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.teleExtension ? (
              <small className="text-danger">{errors.teleExtension.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="text-[#959595] mb-[0.75rem] text-base" htmlFor="input-telephone">
              Landline Number
            </label>
            <Controller
              name="telephone"
              control={control}
              render={({ field }) => {
                const { ref, ...rest } = field
                return (
                  <PatternFormat
                    format="(###) ###-####"
                    className={`formatted-telephone-input bg-[#fff] flex px-[11px] py-[12px] w-full rounded-xl border border-${
                      errors.telephone ? "danger" : isTelephoneFocused ? "primary" : "#dfdfdf"
                    } focus:outline-none`}
                    id="input-telephone"
                    {...rest}
                    placeholder="Enter Landline Number"
                    onChange={e => rest.onChange(handleMobileChange(e))}
                    onFocus={handleTelephoneFocus}
                    onBlur={handleTelephoneBlur}
                  />
                )
              }}
            />
            {errors.telephone ? (
              <small className="text-danger">{errors.telephone.message}</small>
            ) : null}
          </Col>

          <>
            <Col xs={24} md={12} className="mb-4">
              <label
                className="text-[#959595] mb-[0.75rem] text-base"
                htmlFor="input-healthcare-provider">
                Your Healthcare Organization <span className="text-danger">*</span>
              </label>
              <Controller
                name="healthcareProvider"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="input-healthcare-provider"
                    className="capitalize"
                    status={errors.healthcareProvider ? "error" : undefined}
                    value={field.value} // Use field.value from react-hook-form
                    onChange={e => {
                      field.onChange(e) // Notify react-hook-form of the change
                      setFormData(prevData => ({
                        ...prevData,
                        healthcareProvider: e.target.value // Update the corresponding field in formData
                      }))
                    }}
                    disabled
                  />
                )}
              />
              {errors.healthcareProvider ? (
                <small className="text-danger">{errors.healthcareProvider.message}</small>
              ) : null}
            </Col>
          </>
          {formData?.userType === "facility" ? (
            <></>
          ) : (
            <>
            <Col xs={24} md={24} className="mb-4">
              <label className="text-[#959595] mb-[0.75rem] text-base" htmlFor="input-address">
                Primary Address
              </label>
              <Controller
                name="primaryAddress"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <GoogleMapUseAuto
                    setAddress={setAddress}
                    // {...field}
                    dbAddress={outpatientAddress}
                    error={errors?.primaryAddress?.message}
                    setValue={setValue}
                    setIsSameShippingAddrs={() => {}}
                  />
                )}
              />
            </Col>
            {fields.map(field => (
              <AddressFormField
                key={field.name}
                name={field.name}
                control={control}
                errors={errors}
                label={field.label}
                placeholder={field.placeholder}
                maxLength={field.maxLength}
                type={field.type}
                isRequired={field.isRequired}
                webView={false}

              />
            ))}
            </>
          )}
          {error || postAddressError ? (
            <Col className="mb-4" xs={24}>
              <p className="text-danger font-semibold">{error || postAddressError}</p>
            </Col>
          ) : null}
        </Row>
        <Row
          className={`w-full mt-2  bottom-1 flex justify-between ${
            !window?.isMobileVersion ? "pb-4" : "pb-4"
          }`}>
          <Col xs={12} md={12} className="mb-2 pr-2 pl-0">
            <Button type="primary" onClick={prevPage} style={{ minWidth: 0 }} className="w-full">
              Back
            </Button>
          </Col>
          <Col xs={12} md={12} className="mb-2 pr-0 pl-2">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading || postAddressLoading}
              style={{ minWidth: 0 }}
              className="w-full">
              Next
            </Button>
          </Col>
        </Row>
      </form>
    </>
  )
}
export default PersonalDetailForm
