import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getEquipmentStatusAction,
  postEquipmentMethodStatusAction
} from "../../../../redux/actions/equipmentStatusAction"
import { EquipmentSatusOptions } from "../../Types"
import StepsFooter from "./StepsFooter"
import Loading from "../../../layouts/Loading/Loading"
import SuggestedDevicesWeb from "./SuggestedDevicesWeb"
import VanActiveImg from "../../../../assets/delivery-car-active.png"
import VanDeactiveImg from "../../../../assets/delivery-car-deactive.png"
import CartActiveImg from "../../../../assets/cart-active.png"
import CartDeactiveImg from "../../../../assets/cart-deactive.png"
import ShippingAddressWeb from "./ShippingAddressWeb"

const btnStyle = {
  borderRadius: 6,
  height: 75,
  border: "1px solid #666B7B",
  padding: "4px 10px",
  minWidth: 400,
  display: "flex",
  alignItems: "center",
  gap: 10
}

const btnIconStyle = {
  border: "1px solid #666B7B",
  borderRadius: 6,
  padding: 8,
  width: 45
}

const DELIVERY_REQUESTED = EquipmentSatusOptions?.[0]
const WILL_PICK_UP = EquipmentSatusOptions?.[1]

const DeliveryMode = ({ patientId, handleBack, setShowDeliveryMode, setShowPickupDevices }) => {
  const [mode, setMode] = useState(null)
  const dispatch = useDispatch()

  const { error: postError, loading: postLoading } = useSelector(
    state => state.postEquipmentMethodOption
  )
  const {
    getEquipmentStatus,
    loading: equipmentStatusLoading,
    error: equipmentStatusError
  } = useSelector(state => state.getEquipmentStatus)

  useEffect(() => {
    if (!patientId) return
    dispatch(getEquipmentStatusAction(patientId))
  }, [patientId])

  useEffect(() => {
    if (!getEquipmentStatus?.status) return
    setMode(EquipmentSatusOptions?.find(obj => obj?.label === getEquipmentStatus?.status)?.value)
  }, [getEquipmentStatus])

  const handleSubmit = async () => {
    const result = dispatch(postEquipmentMethodStatusAction(patientId, { status: mode }))
    result.then(res => {
      if (res?.status === 200 || res?.status === 201) {
        setShowDeliveryMode(false)
        dispatch(getEquipmentStatusAction(patientId))
        if (mode === WILL_PICK_UP?.value) setShowPickupDevices(true)
      }
    })
  }

  return (
    <>
      <h1>Device Delivery</h1>
      <p className="title">How do you want to pick up the device(s)?</p>
      <p className="secondary">Please select the way in which you want to get the device(s)</p>

      {equipmentStatusLoading ? (
        <Loading />
      ) : (
        <>
          <div className="mt-4 inline-flex flex-col gap-8">
            <button
              style={btnStyle}
              className={mode === WILL_PICK_UP?.value ? "bg-primary text-[#fff]" : "bg-[#fff]"}
              onClick={() => mode !== WILL_PICK_UP?.value && setMode(WILL_PICK_UP?.value)}>
              <img
                src={mode === WILL_PICK_UP?.value ? CartActiveImg : CartDeactiveImg}
                className={mode === WILL_PICK_UP?.value ? "bg-[#fff]" : ""}
                style={btnIconStyle}
              />
              I want to pick up the device(s) from the clinic
            </button>
            <button
              style={btnStyle}
              className={
                mode === DELIVERY_REQUESTED?.value ? "bg-primary text-[#fff]" : "bg-[#fff]"
              }
              onClick={() =>
                mode !== DELIVERY_REQUESTED?.value && setMode(DELIVERY_REQUESTED?.value)
              }>
              <img
                src={mode === DELIVERY_REQUESTED?.value ? VanActiveImg : VanDeactiveImg}
                className={mode === DELIVERY_REQUESTED?.value ? "bg-[#fff]" : ""}
                style={btnIconStyle}
              />
              I want to get the device(s) shipped
            </button>
          </div>

          <StepsFooter
            handleBack={handleBack}
            nextBtnLoading={postLoading}
            handleNext={handleSubmit}
            nextBtnDisable={!mode}
          />
        </>
      )}
    </>
  )
}

const DeviceDeliveryWeb = ({ formData, setCurrentStep }) => {
  const [showDeliveryMode, setShowDeliveryMode] = useState(true)
  const [showShippingDevices, setShowShippingDevices] = useState(false)
  const [showPickupDevices, setShowPickupDevices] = useState(false)

  const { getEquipmentStatus } = useSelector(state => state.getEquipmentStatus)

  return (
    <div className="device-delivery">
      {formData?.userType === "outpatient" && showDeliveryMode && (
        <DeliveryMode
          patientId={formData?.id}
          handleBack={() => setCurrentStep(prev => prev - 1)}
          setShowDeliveryMode={setShowDeliveryMode}
          setShowPickupDevices={setShowPickupDevices}
        />
      )}
      {formData?.userType === "outpatient" && !showDeliveryMode && showPickupDevices && (
        <SuggestedDevicesWeb
          patientId={formData?.id}
          handleBack={() => {
            setShowDeliveryMode(true)
            setShowPickupDevices(false)
          }}
        />
      )}

      {formData?.userType === "outpatient" &&
        !showShippingDevices &&
        !showDeliveryMode &&
        getEquipmentStatus?.status?.trim() === DELIVERY_REQUESTED?.label && (
          <ShippingAddressWeb
            patientId={formData?.id}
            handleBack={() => setShowDeliveryMode(true)}
            setShowShippingDevices={setShowShippingDevices}
          />
        )}

      {formData?.userType === "outpatient" && showShippingDevices && !showDeliveryMode && (
        <SuggestedDevicesWeb
          patientId={formData?.id}
          handleBack={() => setShowShippingDevices(false)}
        />
      )}

      {formData?.userType === "facility" && (
        <SuggestedDevicesWeb
          patientId={formData?.id}
          handleBack={() => setCurrentStep(prev => prev - 1)}
        />
      )}
    </div>
  )
}

export default DeviceDeliveryWeb
