import Page404 from "../views/404"
import LoginView from "../views/LoginView"
import MobileCCM from "../views/MobileCCM"
import MobileRPM from "../views/MobileRPM"
import AccountView from "../views/AccountView"
import AuthGuard from "../components/AuthGuard"
import DashboardView from "../views/DashboardView"
import GlucoseReadings from "../views/GlucoseReadings"
import PatientProvider from "../views/PatientProvider"
import DeleteAccount from "../components/DeleteAccount"
import PatientDetailView from "../views/PatientDetailView"
import ForgotPasswordView from "../views/ForgotPasswordView"
import WeightReadingsView from "../views/WeightReadingsView"
import OximeterReadingsView from "../views/OximeterReadingsView"
import BloodPressureReadings from "../views/BloodPressureReadings"
import RPMViewCare from "../components/RPMAdmin/RPMViewCare/RPMViewCare"
import CCMViewCare from "../components/CCMAdmin/CCMViewCare/CCMViewCare"
// import AddConsultation from "../components/consultation/AddConsultation"
// import PastConsultations from "../components/consultation/PastConsultations"
// import UpcomingConsultation from "../components/consultation/UpcomingConsultation"
import CCMViewPrint from "../components/CCMAdmin/CCMViewCare/Component/CCMViewQuestion/CCMViewPrint"
import RPMViewPrint from "../components/RPMAdmin/RPMViewCare/Component/RPMViewQuestion/RPMViewPrint"
// import RtmView from "../views/RtmView"
// import MobilePainMgtView from "../views/MobilePainMgtView"
import BookAppointment from "../components/BookAppointment/BookAppointment";
import PCMViewCare from "../components/PCMAdmin/PCMViewCare/PCMViewCare"
import ContactUs from "../components/ContactUs/ContactUs"
import MainLayout from "../layout/MainLayout"
import MobilePCM from "../views/MobilePCM"
import IdentifyPatient from "../components/IdentifyPatient"
import SelfOnboardingWelcome from "../components/SelfOnboardingWelcome"
import SelfOnboardingWelcomeWeb from "../components/SelfOnboardingWelcome/Web"

const Routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <MainLayout>
          <DashboardView />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/login",
    element: <LoginView />
  },
  {
    path: "/identify-patient",
    element: <IdentifyPatient />
  },
  {
    path: "/settings",
    element: (
      <AuthGuard>
        <MainLayout>
          <AccountView />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/mobile-ccm",
    element: <MobileCCM />
  },
  {
    path: "/mobile-pcm",
    element: <MobilePCM />
  },
  {
    path: "/mobile-rpm",
    element: <MobileRPM />
  },
  {
    path: "/welcome",
    element: <SelfOnboardingWelcome /> // This route is for Mobile UI
  },
  {
    path: "/web/welcome",
    element: <SelfOnboardingWelcomeWeb /> // This is for Web UI
  },
  {
    path: "/contact",
    element: <ContactUs />
  },
  {
    path: "/profile",
    element: (
      <AuthGuard>
        <MainLayout>
          <PatientDetailView />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/vitals/weight-machine",
    element: (
      <AuthGuard>
        <MainLayout>
          <WeightReadingsView />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/vitals/blood-pressure",
    element: (
      <AuthGuard>
        <MainLayout>
          <BloodPressureReadings />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/vitals/oximeter",
    element: (
      <AuthGuard>
        <MainLayout>
          <OximeterReadingsView />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/vitals/glucose",
    element: (
      <AuthGuard>
        <MainLayout>
          <GlucoseReadings />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/provider",
    element: (
      <AuthGuard>
        <MainLayout>
          <PatientProvider />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/ccm-view-care",
    element: (
      <AuthGuard>
        <MainLayout>
          <CCMViewCare />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/pcm-view-care",
    element: (
      <MainLayout>
        <AuthGuard>
          <PCMViewCare />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/delete-account",
    element: (
      <AuthGuard>
        <MainLayout>
          <DeleteAccount />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/rpm-view-treatement",
    element: (
      <AuthGuard>
        <MainLayout>
          <RPMViewCare />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/ccm-view-care-print",
    element: (
      <AuthGuard>
        <MainLayout>
          <CCMViewPrint />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/forget-password",
    element: <ForgotPasswordView />
  },
  {
    path: "/rpm-view-treatement-print",
    element: (
      <AuthGuard>
        <MainLayout>
          <RPMViewPrint />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/book-weekly-appointment",
    element: (
      <AuthGuard>
        <MainLayout>
          <BookAppointment />
        </MainLayout>
      </AuthGuard>
    )
  },
  // {
  //   path: "/rtm",
  //   element: (
  //     <AuthGuard>
  //       <MainLayout>
  //         <RtmView />
  //       </MainLayout>
  //     </AuthGuard>
  //   )
  // },
  // {
  //   path: "/mobile-pain-management-view",
  //   element: <MobilePainMgtView />
  // },
  // {
  //   path: "/consultations/upcoming",
  //   element: (
  //     <AuthGuard>
  //       <MainLayout>
  //         <UpcomingConsultation />
  //       </MainLayout>
  //     </AuthGuard>
  //   )
  // },
  // {
  //   path: "/consultations/past",
  //   element: (
  //     <AuthGuard>
  //       <MainLayout>
  //         <PastConsultations />
  //       </MainLayout>
  //     </AuthGuard>
  //   )
  // },
  // {
  //   path: "/consultations/new",
  //   element: (
  //     <AuthGuard>
  //       <MainLayout>
  //         <AddConsultation />
  //       </MainLayout>
  //     </AuthGuard>
  //   )
  // },
  {
    path: "*",
    element: <Page404 />
  }
]

export default Routes
