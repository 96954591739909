import React, { useState, useEffect } from "react"
import { ConfigProvider } from "antd"
import { useNavigate, useLocation } from "react-router-dom"
import StartJourneyWeb from "./StartJourneyWeb"
import DetailsStepper from "./DetailsStepper/DetailsStepper"
import "./styles.css"
import { useDispatch, useSelector } from "react-redux"
import { getPatientMonitoringData } from "../../../redux/actions/patientAction"

const SelfOnboardingWelcomeWeb = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const [currentStep, setCurrentStep] = useState(1)
  const [showStartJourney, setShowStartJourney] = useState(false)

  const { patientMonitoringData } = useSelector(state => state.patientMonitoringData)

  useEffect(() => {
    if (location?.state?.patientId && location?.state?.fromLogin) {
      setShowStartJourney(true)
      dispatch(getPatientMonitoringData(location?.state?.patientId))
    } else navigate("/login")
  }, [location])

  const next = () => setCurrentStep(prev => prev + 1)

  const prevPage = () => setCurrentStep(prev => prev - 1)

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Input: { controlHeight: 48, borderRadius: 6, colorBgContainer: "#FFF" },
            Select: { controlHeight: 48, borderRadius: 6, colorBgContainer: "#FFF" },
            Button: { controlHeight: 42, borderRadius: 12, fontSize: 16 }
          }
        }}>
        <div className="selfonboarding-welcome-web">
          {currentStep === 1 && showStartJourney && (
            <StartJourneyWeb next={next} patientId={location?.state?.patientId} />
          )}
          {currentStep === 2 && (
            <DetailsStepper
              prevPage={prevPage}
              patientId={location?.state?.patientId}
              showDeviceDelivery={Boolean(patientMonitoringData?.rpm?.length)}
            />
          )}
        </div>
      </ConfigProvider>
    </>
  )
}

export default SelfOnboardingWelcomeWeb
