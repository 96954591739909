import React from "react"
import { Controller } from "react-hook-form"
import { Input, Col } from "antd"
import FloatInput from "./FloatInput/FloatInput"

const AddressFormField = ({
  name,
  control,
  errors,
  type = "text",
  label,
  placeholder,
  isRequired = false,
  maxLength = undefined,
  webView
}) => {
  const handleOnChange = (name, type, field) => e => {
    let value = e.target.value
    if (type === "number") {
      // Allow only digits for number fields
      value = value.replace(/[^0-9]/g, "").slice(0, 5)
    } 
    field.onChange(value)
  }

  return (
    <>
      {webView ? (
        <Col xs={24} md={8} className="mb-[1rem]">
          <Controller
            name={name}
            control={control}
            rules={{ required: isRequired, maxLength }}
            render={({ field }) => (
              <FloatInput label={label} required={isRequired}>
                <Input
                  type={type}
                  id={`input-${name}`}
                  status={errors[name] ? "error" : undefined}
                  {...field}
                  onChange={handleOnChange(name, type, field)}
                  className="bg-[#fff]"
                />
              </FloatInput>
            )}
          />
          {errors[name] && <small className="text-danger">{errors[name].message}</small>}
        </Col>
      ) : (
        <Col xs={24} md={12} className="mb-[1rem]">
          <label className="text-[#959595] mb-[0.75rem] text-base" htmlFor={`input-${name}`}>
            {label} {isRequired && <span className="text-danger">*</span>}
          </label>
          <Controller
            name={name}
            control={control}
            rules={{ required: isRequired, maxLength }}
            render={({ field }) => (
              <Input
                type={type}
                id={`input-${name}`}
                status={errors[name] ? "error" : undefined}
                {...field}
                onChange={handleOnChange(name, type, field)}
                placeholder={placeholder}
                className="bg-[#fff]"
              />
            )}
          />
          {errors[name] && <small className="text-danger">{errors[name].message}</small>}
        </Col>
      )}
    </>
  )
}

export default AddressFormField
