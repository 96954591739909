import { Col, Input, Row } from "antd"
import GoogleMapUseAuto from "../../GoogleMapUseAuto"
import Loading from "../../../layouts/Loading/Loading"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import {
  getShippingAddressAction,
  postShippingAddressAction,
  updateShippingAddressAction
} from "../../../../redux/actions/patientAction"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import * as yup from "yup"
import StepsFooter from "./StepsFooter"
import FloatInput from "../../../../utils/FloatInput/FloatInput"
import AddressFormField from "../../../../utils/AddressFormField"
import fields from "../../../../utils/addressFields"

const INVALID_STREET_ERR = "Street is required"
const INVALID_CITY_ERR = "City is required"
const INVALID_STATE_ERR = "State is required"
const INVALID_ZIP_ERR = "Zip code is required"

const ShippingAddressWeb = ({ patientId, handleBack, setShowShippingDevices }) => {
  patientId = JSON.parse(localStorage.getItem("id"))

  const [address, setAddress] = useState()
  const [isSameShippingAddrs, setIsSameShippingAddrs] = useState(false)

  const { shippingAddress, loading: shippingFetchLoading } = useSelector(
    state => state.shippingAddress
  )
  const { outpatientAddress, loading: fetchAddressLoading } = useSelector(
    state => state.outpatientAddress
  )
  const { error: postShippingError, loading: postShippingLoading } = useSelector(
    state => state.postShippingAddress
  )
  const { error: updateShippingError, loading: updateShippingLoading } = useSelector(
    state => state.updateShippingAddress
  )
  const dispatch = useDispatch()
  const schema = yup.object({
    // shippingAddressField: yup.string().required("Shipping address is required")
    street: yup.string().required(INVALID_STREET_ERR),
    city: yup.string().required(INVALID_CITY_ERR),
    state: yup.string().required(INVALID_STATE_ERR),
    zipcode: yup.string().required(INVALID_ZIP_ERR),
    apartmentNumber: yup.string().nullable(),
  })

  const {
    reset,
    control,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })
  useEffect(() => {
    dispatch(getShippingAddressAction(patientId))
  }, [])
  // if both ship and primary address same set the checkbox checked
  useEffect(() => {
    if (
      outpatientAddress?.street?.toLowerCase() === shippingAddress?.street?.toLowerCase() &&
      outpatientAddress?.city?.toLowerCase() === shippingAddress?.city?.toLowerCase() &&
      outpatientAddress?.state?.toLowerCase() === shippingAddress?.state?.toLowerCase() &&
      outpatientAddress?.flatNo?.toLowerCase() === shippingAddress?.flatNo?.toLowerCase() &&
      outpatientAddress?.zipcode === shippingAddress?.zipcode
    ) {
      setIsSameShippingAddrs(true)
    } else {
      setIsSameShippingAddrs(false)
    }
  }, [shippingFetchLoading, fetchAddressLoading])

  useEffect(() => {
    setAddress(shippingAddress)
  }, [shippingFetchLoading])

  useEffect(() => {
    setValue("apartmentNumber", address?.flatNo ? address?.flatNo : null)
    setValue("street", address?.street)
    setValue("city", address?.city)
    setValue("state", address?.state)
    setValue("zipcode", address?.zipcode)
  }, [address])

  useEffect(() => {
    if (isSameShippingAddrs) {
      setValue("apartmentNumber", outpatientAddress?.flatNo ? outpatientAddress?.flatNo : null)
      setValue("street", outpatientAddress?.street)
      setValue("city", outpatientAddress?.city)
      setValue("state", outpatientAddress?.state)
      setValue("zipcode", outpatientAddress?.zipcode)
    }
  }, [isSameShippingAddrs])
  
  const onSubmit = async data => {
    let formValues = {
      street: data.street,
      city: data?.city,
      state: data?.state,
      zipcode: data?.zipcode,
      flatNo: data?.apartmentNumber?.length ? data?.apartmentNumber : null
    }
    let shippingResult = !shippingAddress?.id
      ? dispatch(postShippingAddressAction(patientId, formValues))
      : dispatch(updateShippingAddressAction(patientId, formValues))
    shippingResult.then(res => {
      if (res?.message?.toLowerCase() === "success") {
        dispatch(getShippingAddressAction(patientId))
        // next()
        setShowShippingDevices(true)
      }
    })
  }

  return (
    <>
      <h1>Device Delivery</h1>
      <div className="mt-6 mb-16">
        {!shippingFetchLoading ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row gutter={20}>
              <Col xs={24} md={24} className="mb-4">
                <Controller
                  name="shippingAddressField"
                  control={control}
                  // rules={{ required: true }}
                  render={({ field }) => (
                    <GoogleMapUseAuto
                      webView={true}
                      label="Shipping Address"
                      setAddress={setAddress}
                      // {...field}
                      dbAddress={shippingAddress}
                      error={errors?.shippingAddressField?.message}
                      setValue={setValue}
                      from="shippingAddress"
                      setIsSameShippingAddrs={setIsSameShippingAddrs}
                      isSameShippingAddrs={isSameShippingAddrs}
                    />
                  )}
                />
              </Col>
              {fields.map(field => (
                <AddressFormField
                  key={field.name}
                  name={field.name}
                  control={control}
                  errors={errors}
                  label={field.label}
                  placeholder={field.placeholder}
                  maxLength={field.maxLength}
                  type={field.type}
                  isRequired={field.isRequired}
                  webView={true}
                />
              ))}
              {updateShippingError || postShippingError ? (
                <Col className="mb-4" xs={24}>
                  <p className="text-danger font-semibold">
                    {postShippingError || updateShippingError}
                  </p>
                </Col>
              ) : null}
            </Row>

            <StepsFooter
              handleBack={handleBack}
              nextBtnLoading={postShippingLoading || updateShippingLoading}
            />
          </form>
        ) : (
          <Loading />
        )}
      </div>
    </>
  )
}

export default ShippingAddressWeb
