import React, { useEffect, useState } from "react"
import { Button, Col, Row } from "antd"
import { Helmet } from "react-helmet"
import GoogleMapUseAuto from "./GoogleMapUseAuto"
import Loading from "../layouts/Loading/Loading"
import { useDispatch, useSelector } from "react-redux"
import { Controller, useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  getShippingAddressAction,
  postShippingAddressAction,
  updateShippingAddressAction
} from "../../redux/actions/patientAction"
import AddressFormField from "../../utils/AddressFormField"
import fields from "../../utils/addressFields"

const INVALID_STREET_ERR = "Street is required"
const INVALID_CITY_ERR = "City is required"
const INVALID_STATE_ERR = "State is required"
const INVALID_ZIP_ERR = "Zip code is required"

const ShippingAddress = ({ next, patientId, prevPage }) => {
  patientId = JSON.parse(localStorage.getItem("id"))
  const [address, setAddress] = useState()
  const [isSameShippingAddrs, setIsSameShippingAddrs] = useState(false)

  const { shippingAddress, loading: shippingFetchLoading } = useSelector(
    state => state.shippingAddress
  )
  const { outpatientAddress, loading: fetchAddressLoading } = useSelector(
    state => state.outpatientAddress
  )
  const { error: postShippingError, loading: postShippingLoading } = useSelector(
    state => state.postShippingAddress
  )
  const { error: updateShippingError, loading: updateShippingLoading } = useSelector(
    state => state.updateShippingAddress
  )
  const dispatch = useDispatch()

  const schema = yup.object({
    street: yup.string().required(INVALID_STREET_ERR),
    city: yup.string().required(INVALID_CITY_ERR),
    state: yup.string().required(INVALID_STATE_ERR),
    zipcode: yup.string().required(INVALID_ZIP_ERR),
    apartmentNumber: yup.string().nullable()
  })

  const {
    reset,
    control,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    dispatch(getShippingAddressAction(patientId))
  }, [dispatch, patientId])

  useEffect(() => {
    if (
      outpatientAddress?.street?.toLowerCase() === shippingAddress?.street?.toLowerCase() &&
      outpatientAddress?.city?.toLowerCase() === shippingAddress?.city?.toLowerCase() &&
      outpatientAddress?.state?.toLowerCase() === shippingAddress?.state?.toLowerCase() &&
      outpatientAddress?.flatNo?.toLowerCase() === shippingAddress?.flatNo?.toLowerCase() &&
      outpatientAddress?.zipcode === shippingAddress?.zipcode
    ) {
      setIsSameShippingAddrs(true)
    } else {
      setIsSameShippingAddrs(false)
    }
  }, [shippingFetchLoading, fetchAddressLoading, outpatientAddress, shippingAddress])

  useEffect(() => {
    setAddress(shippingAddress)
  }, [shippingFetchLoading, shippingAddress])

  useEffect(() => {
    setValue("apartmentNumber", address?.flatNo ? address?.flatNo : null)
    setValue("street", address?.street)
    setValue("city", address?.city)
    setValue("state", address?.state)
    setValue("zipcode", address?.zipcode)
  }, [address, setValue])

  useEffect(() => {
    if (isSameShippingAddrs) {
      setValue("apartmentNumber", outpatientAddress?.flatNo ? outpatientAddress?.flatNo : null)
      setValue("street", outpatientAddress?.street)
      setValue("city", outpatientAddress?.city)
      setValue("state", outpatientAddress?.state)
      setValue("zipcode", outpatientAddress?.zipcode)
    }
  }, [isSameShippingAddrs, outpatientAddress, setValue])

  const onSubmit = async data => {
    const formValues = {
      street: data.street,
      city: data.city,
      state: data.state,
      zipcode: data.zipcode,
      flatNo: data.apartmentNumber?.length ? data.apartmentNumber : null
    }
    const shippingResult = !shippingAddress?.id
      ? dispatch(postShippingAddressAction(patientId, formValues))
      : dispatch(updateShippingAddressAction(patientId, formValues))
    shippingResult.then(res => {
      if (res?.message?.toLowerCase() === "success") {
        dispatch(getShippingAddressAction(patientId))
        next()
      }
    })
  }

  window.back = () => {
    prevPage()
  }

  return (
    <>
      <Helmet>
        <script>{`
          if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.navigationTitle) {
            window.webkit.messageHandlers.navigationTitle.postMessage("Your Details");
          } else {
            console.error("window.webkit.messageHandlers.navigationTitle is undefined. Unable to post message.");
          }
        `}</script>
      </Helmet>
      {!window?.isMobileVersion ? (
        <div className="flex justify-center py-3 text-[1rem] font-semibold shadow-sm ">
          Your Details
        </div>
      ) : null}
      <div className={`${!window?.isMobileVersion ? "pt-1" : "py-6"} px-3`}>
        {!shippingFetchLoading ? (
          <form onSubmit={handleSubmit(onSubmit)} className="h-[90vh] relative">
            <Row gutter={20}>
              <Col xs={24} md={24} className="mb-4">
                <h1 className={`${!window?.isMobileVersion ? "pb-1" : "pb-8"} pt-4`}>Address</h1>
                <label className="text-[#959595] mb-[0.75rem] text-base" htmlFor="input-address">
                  Shipping Address
                </label>
                <Controller
                  name="shippingAddressField"
                  control={control}
                  render={({ field }) => (
                    <GoogleMapUseAuto
                      setAddress={setAddress}
                      dbAddress={shippingAddress}
                      error={errors?.shippingAddressField?.message}
                      setValue={setValue}
                      from="shippingAddress"
                      isSameShippingAddrs={isSameShippingAddrs}
                      setIsSameShippingAddrs={setIsSameShippingAddrs}
                    />
                  )}
                />
              </Col>
              {fields.map(field => (
                <AddressFormField
                  key={field.name}
                  name={field.name}
                  control={control}
                  errors={errors}
                  label={field.label}
                  placeholder={field.placeholder}
                  maxLength={field.maxLength}
                  type={field.type}
                  isRequired={field.isRequired}
                  webView={false}

                />
              ))}
              {updateShippingError || postShippingError ? (
                <Col className="mb-4" xs={24}>
                  <p className="text-danger font-semibold">
                    {postShippingError || updateShippingError}
                  </p>
                </Col>
              ) : null}
            </Row>
            <Row
              className={`mt-2 mb-16 flex justify-center ${
                !window?.isMobileVersion ? "pb-4" : "pb-0"
              }`}>
              <Col xs={12} md={12} className="mb-2 pr-2 pl-0">
                <Button
                  type="primary"
                  onClick={prevPage}
                  style={{ minWidth: 0 }}
                  className="w-full">
                  Back
                </Button>
              </Col>
              <Col xs={12} md={12} className="mb-2 pr-0 pl-2">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={postShippingLoading || updateShippingLoading}
                  style={{ minWidth: 0 }}
                  className="w-full">
                  Next
                </Button>
              </Col>
            </Row>
          </form>
        ) : (
          <Loading />
        )}
      </div>
    </>
  )
}

export default ShippingAddress
