import { useState, useMemo, useEffect, useRef } from "react"
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api"
import usePlacesAutocomplete, { getDetails, getGeocode, getLatLng } from "use-places-autocomplete"
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption
} from "@reach/combobox"
import "./styles.css"
import "@reach/combobox/styles.css"
import { Checkbox, Col, Spin } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { SearchOutlined } from "@ant-design/icons"
import FloatInput from "../../utils/FloatInput/FloatInput"

// Define libraries as a static class property or constant
const libraries = ["places"]

export default function GoogleMapUseAuto({
  webView,
  label,
  error,
  setValue,
  setAddress,
  dbAddress,
  from,
  isSameShippingAddrs,
  setIsSameShippingAddrs
}) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries: libraries
    // componentRestrictions: { country: "US" } // Restrict suggestions to US addresses
  })

  if (!isLoaded)
    return (
      <div className="flex justify-center">
        <Spin />
      </div>
    )
  return (
    <Map
      webView={webView}
      label={label}
      error={error}
      setValueFromParent={setValue}
      setAddress={setAddress}
      dbAddress={dbAddress}
      from={from}
      isSameShippingAddrs={isSameShippingAddrs}
      setIsSameShippingAddrs ={setIsSameShippingAddrs}
    />
  )
}

function Map({ error, setValueFromParent, setAddress, dbAddress, from, isSameShippingAddrs, setIsSameShippingAddrs, webView, label }) {
  const [selected, setSelected] = useState(null)
  const [lat, setLat] = useState(40.71)
  const [lng, setLng] = useState(-74.0)

  const { outpatientAddress, loading: fetchAddressLoading } = useSelector(
    state => state.outpatientAddress
  )
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))

  const center = useMemo(() => ({ lat, lng }), [lat, lng])
  const dispatch = useDispatch()
  const mapRef = useRef(null) // Define mapRef
  const updateCenter = (lat, lng) => {
    setLat(lat)
    setLng(lng)
  }
  useEffect(() => {
    const fetchGeocode = async () => {
      try {
        if (Object.keys(dbAddress ?? {}).length) {
          const fullAddressflatNo = dbAddress?.flatNo ? ` ${dbAddress?.flatNo}` :  ""
          const fullAddress =
            dbAddress?.street +
            fullAddressflatNo +
            ", " +
            dbAddress?.city +
            ", " +
            dbAddress?.state +
            ", " +
            dbAddress?.zipcode

          // Set full address to form field
          setValue(fullAddress, false)

          // Fetch geocode results
          const results = await getGeocode({ address: fullAddress })

          if (results.length > 0) {
            // Get latitude and longitude from results
            const { lat, lng } = await getLatLng(results[0])

            // Update selected location and center
            setSelected({ lat, lng })
            updateCenter(lat, lng)

            // Update form field with formatted address
            const formattedAddress = results[0]?.formatted_address
           // setValue(formattedAddress)
          } else {
            throw new Error("No geocode results found.")
          }
        } else if (from === "shippingAddress") { // to make the marker visible if shipping address is empty
          const fullShipAddressflatNo = outpatientAddress?.flatNo ? ` ${outpatientAddress?.flatNo}` :  ""

          const fullAddress =
            outpatientAddress?.street +
            fullShipAddressflatNo +
            ", " +
            outpatientAddress?.city +
            ", " +
            outpatientAddress?.state +
            ", " +
            outpatientAddress?.zipcode

          // Fetch geocode results
          const results = await getGeocode({ address: fullAddress })

          if (results.length > 0) {
            // Get latitude and longitude from results
            const { lat, lng } = await getLatLng(results[0])

            // Update selected location and center
            setSelected({ lat, lng })
            updateCenter(lat, lng)
          }
        }
      } catch (error) {
        console.error("Error fetching geocode:", error)
        // Handle error state or alert the user about the issue
      }
    }

    fetchGeocode()
  }, [dbAddress])

  const usaBounds = useMemo(
    () =>
      new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(24.396308, -125.0), // Southwest corner of the USA
        new window.google.maps.LatLng(49.384358, -66.93457) // Northeast corner of the USA
      ),
    []
  )

  const options = {
    gestureHandling: "cooperative",
    zoomControl: true,
    zoom: 16,  // Initial zoom level
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: true,
    clickableIcons: false,
    fullscreenControl: true,
    restriction: {
      latLngBounds: usaBounds,
      strictBounds: false // Allows the map to pan beyond the bounds to fit markers
    }
  }

  const {
    ready,
    value,
    setValue,
    suggestions: { data, status },
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      // types:['address', 'postal_code'],

      componentRestrictions: { country: "us" } // Restrict suggestions to US addresses
    }
  })
  const handleSameAddress = e => {
    if (e.target.checked) {
      const shipAddressFlat = outpatientAddress?.flatNo ? `${outpatientAddress?.flatNo}` : ""
      setIsSameShippingAddrs(true)
      const fullShippingAddress =
        outpatientAddress?.street +
        shipAddressFlat +
        ", " +
        outpatientAddress?.city +
        ", " +
        outpatientAddress?.state +
        ", " +
        outpatientAddress?.zipcode
      setValueFromParent("shippingAddressField", fullShippingAddress)
      setValue(fullShippingAddress)
    } else if (!dbAddress?.length) {
      // check if already shipping address is there
      setIsSameShippingAddrs(false)
      setValue("")
    }
  }

  const handleSelect = async address => {
    setValue(address, false)
    clearSuggestions()
    dispatch({ type: "UPDATE_PATIENT_ADDRESS_RESET" })
    const results = await getGeocode({ address })
    const { lat, lng } = await getLatLng(results[0])

    let zipCode = ""
    let city = ""
    let state = ""
    let apartmentNumber = null
    const street = []
    results[0]?.address_components?.forEach(component => {
      const componentType = component.types[0]
      if (componentType === "postal_code") zipCode = component.long_name
      else if (componentType === "administrative_area_level_1") state = component.long_name
      else if (componentType === "subpremise") apartmentNumber = component.long_name
      else if (
        ["street_number", "route", "sublocality", "sublocality_level_3"].includes(componentType)
      )
        street.push(component.short_name)
      else if (
        componentType === "locality" ||
        componentType === "sublocality_level_1" ||
        componentType === "political"
      )
        city = component.long_name
    })
    setValueFromParent(
      "primaryAddress",
      street?.length ? street?.join(" ") : "" + ", " + city + ", " + state + ", " + zipCode
    )
    setValueFromParent(
      "shippingAddressField",
      street?.length ? street?.join(" ") : "" + ", " + city + ", " + state + ", " + zipCode
    )
    // to uncheck/check the same address as primary after the search
    if (
      (
        from === "shippingAddress" &&
        street?.join(" ")?.toLocaleLowerCase() === outpatientAddress?.street?.toLowerCase() &&
        outpatientAddress?.city?.toLowerCase() === city?.toLowerCase() &&
        outpatientAddress?.state?.toLowerCase() === state?.toLowerCase() &&
        outpatientAddress?.flatNo?.toLowerCase() === apartmentNumber?.toLowerCase() &&
        outpatientAddress?.zipcode === zipCode
      )
    ) {
      setIsSameShippingAddrs(true)
    }else {
      setIsSameShippingAddrs(false)
    }
    setAddress({ street: street?.length ? street?.join(" ") : "" , flatNo: apartmentNumber?.length ? apartmentNumber : null, city , state, zipcode: zipCode })
    setSelected({ lat, lng })

    if (mapRef.current) {
      mapRef.current.fitBounds(results[0].geometry.viewport)
    }
  }

  return (
    <>
      <Combobox onSelect={handleSelect}>
        <div className="relative">
          {webView ? (
            <FloatInput label={label ? label : "Primary Address"}>
              <ComboboxInput
                className={`mb-2 bg-[#fff] flex px-[11px] py-[12px] w-full rounded-[6px] border border-${"#dfdfdf"} focus:outline-none`}
                value={value}
                onChange={e => {
                  setValue(e.target.value)
                  if (!e.target.value) {
                    setValueFromParent("primaryAddress", "")
                    setValueFromParent("shippingAddressField", "")
                  }
                }}
                disabled={!ready}
              />
            </FloatInput>
          ) : (
            <ComboboxInput
              className={`mb-2 bg-[#fff] flex px-[11px] py-[12px] w-full rounded-xl border border-${"#dfdfdf"} focus:outline-none`}
              value={value}
              onChange={e => {
                setValue(e.target.value)
                if (!e.target.value) {
                  setValueFromParent("primaryAddress", "")
                  setValueFromParent("shippingAddressField", "")
                }
              }}
              disabled={!ready}
              placeholder="Search address"
            />
          )}
          <SearchOutlined
            className={`absolute right-4 top-1/2 transform -translate-y-1/2 text-secondary `}
          />
        </div>
        <ComboboxPopover className="rounded-lg z-[999]">
          <ComboboxList>
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
      {from === "shippingAddress" ? (
        userInfo?.userType !== "facility" ? (
          <Col xs={24} md={24} className="mb-[1rem] text-[#666B78]">
            <Checkbox onChange={handleSameAddress} checked={isSameShippingAddrs}>
              Shipping address is same as primary address
            </Checkbox>
          </Col>
        ) : null
      ) : null}
      {error ? <small className="text-danger">{error}</small> : null}
      <GoogleMap
        zoom={10}
        center={center}
        mapContainerClassName="map-container"
        options={options}
        onLoad={map => (mapRef.current = map)}>
        {selected && (
          <MarkerF
            position={selected}
            draggable={true}
            onDragEnd={async event => {
              const lat = event.latLng.lat()
              const lng = event.latLng.lng()

              try {
                setIsSameShippingAddrs(false)
                dispatch({ type: "UPDATE_PATIENT_ADDRESS_RESET" })
                const results = await getGeocode({ location: { lat, lng } })
                const address = results[0].formatted_address
                setValue(address)
              } catch (error) {
                console.error("Error fetching place details:", error)
              }
            }}
          />
        )}
      </GoogleMap>
    </>
  )
}
