import { Button, Card, Col, Row, Spin } from "antd"
import weightScaleImg from "../../assets/weight-scale.png"
import glucoMonitorImg from "../../assets/glucose-monitor.png"
import bpMonitorImg from "../../assets/bp-monitor.png"
import pulseOxiImg from "../../assets/pulse-oximeter.png"
import { useEffect, useState } from "react"
import { getPatientMonitoringData } from "../../redux/actions/patientAction"
import { useDispatch, useSelector } from "react-redux"
import { getrpmDeviceActions } from "../../redux/actions/rpmAction"
import { updatePatientWelcomeTutorial } from "../../redux/actions/selfOnboardPatientAction"
import { useNavigate } from "react-router-dom"
import Loading from "../layouts/Loading/Loading"
import { getEquipmentStatusAction } from "../../redux/actions/equipmentStatusAction"
import { EquipmentSatusOptions } from "./Types"
import EnrollmentSplCodeModal from "./EnrollmentSplCodeModal"

const SuggestedDevices = ({ patientId, prevPage }) => {
  patientId = JSON.parse(localStorage.getItem("id"))
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showWm, setShowWm] = useState(false)
  const [showBp, setShowBp] = useState(false)
  const [showOxi, setShowOxi] = useState(false)
  const [showGluco, setShowGluco] = useState(false)
  const [isCodeModalVisible, setIsCodeModalVisible] = useState(false)

  const monitorTypes = JSON.parse(localStorage.getItem("monitorTypes"))

  const { patientMonitoringData, loading } = useSelector(state => state.patientMonitoringData)
  const { loading: updateWelcomeLoading, error: updateWelcomeError } = useSelector(
    state => state.updateSelfOnboaredePatient
  )
  const { loading: rpmDeviceLoading, rpmDevices } = useSelector(state => state.rpmDevices)
  const {
    getEquipmentStatus,
    loading: equipmentStatusLoading,
    error: equipmentStatusError
  } = useSelector(state => state.getEquipmentStatus)
  const { shippingAddress, loading: shippingFetchLoading } = useSelector(
    state => state.shippingAddress
  )
  const { loading: patientLoading, userInfo: patientInfoDetail } = useSelector(
    state => state.userInfo
  )
  useEffect(() => {
    dispatch(getPatientMonitoringData(patientId))
    dispatch(getrpmDeviceActions())
    dispatch(getEquipmentStatusAction(patientId))
  }, [])

  useEffect(() => {
    const programDevice = rpmDevices?.find(({ name }) => name.toLowerCase() === "rpm")?.programs
    if (patientMonitoringData && patientMonitoringData?.rpm && monitorTypes?.length) {
      patientMonitoringData?.rpm?.map(({ programs }) =>
        programs?.map(({ programId, programName }) => {
          if (
            programDevice?.find(
              ({ id, name }) => id === programId && name.toLowerCase() === programName.toLowerCase()
            )
          ) {
            if (programName.toLowerCase() === "weight machine") setShowWm(true)
            else if (programName.toLowerCase() === "blood pressure machine") setShowBp(true)
            else if (programName.toLowerCase() === "oximeter") setShowOxi(true)
            else if (programName.toLowerCase() === "glucose machine") setShowGluco(true)
          }
        })
      )
    } else {
      setShowWm(false)
      setShowBp(false)
      setShowOxi(false)
      setShowGluco(false)
    }
  }, [loading, rpmDeviceLoading])

  const isDone = async () => {
    try {
      const result = await dispatch(updatePatientWelcomeTutorial(patientId))
      // Check if the result has a status property and if it's 200
      if (result && result.status === 200) {
        setIsCodeModalVisible(true)
      } else {
        console.error("Failed to update patient welcome tutorial. Status code:", result?.status)
      }
    } catch (error) {
      // Handle any errors here
      console.error("An error occurred:", error)
    }
  }

  window.back = () => {
    prevPage()
  }
  return (
    <>
      {!loading && !rpmDeviceLoading ? (
        <div className="pt-8 px-3 text-center h-[96vh] flex flex-col relative">
          <div className="flex-1">
            <p className="mx-8">
              Your provider has suggested these device(s) for you at no cost to better monitor your
              health.
            </p>
            <div className="my-4 flex gap-6 justify-center flex-wrap">
              {showWm ? (
                <div className="device-card">
                  <img src={weightScaleImg} alt="Weight Scale" />
                  <h2>Weight Scale</h2>
                </div>
              ) : null}
              {showOxi ? (
                <div className="device-card">
                  <img src={pulseOxiImg} alt="Pulse Oximeter" />
                  <h2>Pulse Oximeter</h2>
                </div>
              ) : null}
              {showBp ? (
                <div className="device-card">
                  <img src={bpMonitorImg} alt="Blood Pressure Machine" />
                  <h2>BP Monitor</h2>
                </div>
              ) : null}
              {showGluco ? (
                <div className="device-card">
                  <img src={glucoMonitorImg} alt="Blood Glucose Machine" />
                  <h2>Glucose Monitor</h2>
                </div>
              ) : null}
            </div>
            {!showGluco && !showBp && !showOxi && !showWm ? <h2>No device(s) selected </h2> : null}
            {!equipmentStatusLoading ? (
              (showGluco || showBp || showOxi || showWm) &&
              patientInfoDetail?.userType !== "facility" ? ( // dont show the device pick info for the "no devices and facility patient"
                EquipmentSatusOptions[1].label.toLowerCase().trim() ===
                getEquipmentStatus?.status?.toLowerCase()?.trim() ? (
                  <p className="mx-8 mt-2">
                    As requested, you can collect your device(s) from the clinic. We will inform you
                    once they are ready for collection.
                  </p>
                ) : (
                  <>
                    <p className="mx-8">
                      <br />
                      Your device(s) will be shipped to this address
                    </p>
                    <p className="capitalize">
                      Address : {" "}
                      {`${shippingAddress?.street} ${
                        shippingAddress?.flatNo ? `#${shippingAddress?.flatNo}` :""
                      }, ${shippingAddress?.city}, ${shippingAddress?.state}, ${
                        shippingAddress?.zipcode
                      }`}
                    </p>
                  </>
                )
              ) : (
                <></>
              )
            ) : (
              <Spin />
            )}
            {updateWelcomeError ? (
              <Col className="mb-4" xs={24}>
                <p className="text-danger font-semibold">{updateWelcomeError}</p>
              </Col>
            ) : null}
          </div>

          <Row className="w-full flex justify-center mt-2 bottom-1">
            <Col xs={12} md={12} className="mb-2 pr-2 pl-0">
              <Button type="primary" onClick={prevPage} style={{ minWidth: 0 }} className="w-full">
                Back
              </Button>
            </Col>
            <Col xs={12} md={12} className="mb-2 pr-0 pl-2">
              <Button
                type="primary"
                onClick={isDone}
                loading={updateWelcomeLoading}
                style={{ minWidth: 0 }}
                className="w-full">
                Confirm & Continue
              </Button>
            </Col>
          </Row>
        </div>
      ) : (
        <Loading />
      )}
      {isCodeModalVisible ? (
        <EnrollmentSplCodeModal
          isCodeModalVisible={isCodeModalVisible}
          setIsCodeModalVisible={setIsCodeModalVisible}
          patientId={patientId}
        />
      ) : null}
    </>
  )
}

export default SuggestedDevices
