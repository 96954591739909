import { Row, Col, Input, Select, Button } from "antd"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller, useFieldArray } from "react-hook-form"
import * as yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import {
  getEmergencyContactDetailsV2Action,
  postEmergencyContactDetailV2Action,
  updateEmergencyContactDetailsV2Action
} from "../../../../redux/actions/patientAction"
import { useEffect, useState } from "react"
import Loading from "../../../layouts/Loading/Loading"
import formatPhoneNumber from "../../../../utils/formatPhoneNumber"
import { PatternFormat } from "react-number-format"
import FloatInput from "../../../../utils/FloatInput/FloatInput"
import StepsFooter from "./StepsFooter"
import { PlusCircleFilled } from "@ant-design/icons"
import EnrollmentSplCodeModal from "../../EnrollmentSplCodeModal"
import { updatePatientWelcomeTutorial } from "../../../../redux/actions/selfOnboardPatientAction"

const alphaRegex = /^[a-zA-Z\s]+$/

const schema = yup
  .object()
  .shape({
    emergencyMobNumber: yup
      .string()
      .nullable()
      .transform(value => (value === "" ? null : value))
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Invalid Mobile Number"),
    EmergencyPersonName: yup
      .string()
      .trim()
      .nullable()
      .transform(value => (value === "" ? null : value))
      .matches(alphaRegex, "Name should contain alphabets only"),
    EmergencyPersonRelation: yup
      .string()
      .trim()
      .nullable()
      .transform(value => (value === "" ? null : value))
      .matches(alphaRegex, "Relationship should contain alphabets only"),
    contactId: yup.string()
  })
  .required()

const RelationshipOptions = [
  "Mother",
  "Father",
  "Spouse",
  "Child",
  "Sibling",
  "Grandparent",
  "Grandchild",
  "Aunt",
  "Uncle",
  "Nephew",
  "Niece",
  "Cousin",
  "Partner",
  "Friend",
  "Colleague",
  "Neighbor",
  "Guardian",
  "Other"
]

const defaultValues = {
  emergencyMobNumber: "",
  EmergencyPersonName: "",
  EmergencyPersonRelation: "",
  contactId: ""
}

const EmergencyContactDetailWeb = ({ patientId, setFormData, setCurrentStep, handleBack }) => {
  const {
    reset,
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: { dynamicForm: [defaultValues] },
    shouldFocusError: false,
    resolver: yupResolver(
      yup.object().shape({
        dynamicForm: yup.array().of(
          yup.object().shape({
            emergencyMobNumber: schema.fields.emergencyMobNumber,
            EmergencyPersonName: schema.fields.EmergencyPersonName,
            EmergencyPersonRelation: schema.fields.EmergencyPersonRelation,
            contactId: schema.fields.contactId
          })
        )
      })
    )
  })

  const watchForm = watch("dynamicForm")

  const { fields, append, replace } = useFieldArray({
    control,
    name: "dynamicForm"
  })

  const { loading: postNewDetailsLoading, error: postNewDetailsApiErr } = useSelector(
    state => state.emergencyContactDetail
  )
  const [isFocused, setIsFocused] = useState(null)

  const [updateDetailsApiErr, setUpdateDetailsApiErr] = useState({})

  const { patientMonitoringData } = useSelector(state => state.patientMonitoringData)
  const [isCodeModalVisible, setIsCodeModalVisible] = useState(false)

  const { getEmergencyContactDetails, loading: emergencyContactDetailLoading } = useSelector(
    state => state.getEmergencyContactDetails
  )

  const { loading: updateLoading } = useSelector(state => state.updateEmergencyContactDetails)

  const { loading: updateWelcomeLoading, error: updateWelcomeError } = useSelector(
    state => state.updateSelfOnboaredePatient
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (!patientId) return
    dispatch(getEmergencyContactDetailsV2Action(patientId))
  }, [patientId])

  useEffect(() => {
    if (!getEmergencyContactDetails?.length) return reset()
    replace(
      getEmergencyContactDetails?.map(contact => ({
        contactId: contact?.id ?? "",
        emergencyMobNumber: formatPhoneNumber(contact?.mobileNumber ?? ""),
        EmergencyPersonName: contact?.name ?? "",
        EmergencyPersonRelation: contact?.relationship ?? ""
      }))
    )
  }, [getEmergencyContactDetails])

  const handleNext = async () => {
    if (!patientMonitoringData?.rpm?.length) {
      // if there is no rpm device(s) then end the flow
      await dispatch(updatePatientWelcomeTutorial(patientId))
      setIsCodeModalVisible(true)
    } else setCurrentStep(prevStep => prevStep + 1)
  }

  const onSubmit = async ({ dynamicForm }) => {
    if (
      !dynamicForm?.[0]?.EmergencyPersonName?.length ||
      !dynamicForm?.[0]?.EmergencyPersonRelation?.length ||
      !dynamicForm?.[0].emergencyMobNumber?.length
    ) {
      handleNext()
      return
    }

    const toCreate = dynamicForm?.filter(data => !data?.contactId)
    const toUpdate = dynamicForm?.filter(data => data?.contactId)
    let flag = true

    if (toCreate?.length) {
      // Don't have existing emergency contact details, so Post new entry for Emergency Contact
      const formDataToPost = toCreate
        ?.filter(
          data =>
            data?.EmergencyPersonName && data?.EmergencyPersonRelation && data?.emergencyMobNumber
        )
        ?.map(data => ({
          name: data.EmergencyPersonName,
          relationship: data.EmergencyPersonRelation,
          mobileNumber: data.emergencyMobNumber?.replace(/[^\d]/g, ""),
          countryCode: "US"
        }))

      const res = await dispatch(postEmergencyContactDetailV2Action(patientId, formDataToPost))
      if (res?.status !== 200) {
        flag = false
      }
    }
    if (toUpdate?.length) {
      // Having existing emergency contact details, so Update the entry for Emergency Contact
      setUpdateDetailsApiErr(null)
      const updatePromises = toUpdate?.map(async data => {
        const formDataToUpdate = {
          name: data.EmergencyPersonName,
          relationship: data.EmergencyPersonRelation,
          mobileNumber: data.emergencyMobNumber?.replace(/[^\d]/g, ""),
          countryCode: "US"
        }
        const res = await dispatch(
          updateEmergencyContactDetailsV2Action(patientId, data.contactId, formDataToUpdate)
        )
        if (res?.status !== 200) {
          setUpdateDetailsApiErr(old => ({ ...old, ...res }))
          flag = false
        }
      })
      await Promise.all(updatePromises)
    }

    if (flag) {
      handleNext()
    }
  }

  const prevPage = () => {
    setCurrentStep(prev => prev - 1)
  }
  window.back = () => {
    prevPage()
  }

  const handleFocus = fieldName => setIsFocused(prev => ({ ...prev, [fieldName]: true }))

  const handleBlur = fieldName => setIsFocused(prev => ({ ...prev, [fieldName]: false }))

  const handleMobileChange = e => {
    const { value } = e.target
    return formatPhoneNumber(value)
  }

  return (
    <>
      {!emergencyContactDetailLoading ? (
        <>
          <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: 60 }}>
            {fields.map((field, index) => (
              <Row gutter={20} key={field.id} className="mb-3">
                <h1 className="ml-2.5 mt-2">Emergency Contact Details</h1>
                <Col xs={24} md={24} className="mb-4">
                  <Controller
                    name={`dynamicForm.${index}.EmergencyPersonName`}
                    control={control}
                    render={({ field }) => (
                      <FloatInput label="Name">
                        <Input
                          status={
                            errors.dynamicForm?.[index]?.EmergencyPersonName ? "error" : undefined
                          }
                          value={field.value} // Use field.value from react-hook-form
                          onChange={e => {
                            if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                              field.onChange(e) // Notify react-hook-form of the change
                              setFormData(prevData => ({
                                ...prevData,
                                [`dynamicForm[${index}].EmergencyPersonName`]: e.target.value // Update the corresponding field in formData
                              }))
                            }
                          }}
                        />
                      </FloatInput>
                    )}
                  />
                  {errors.dynamicForm?.[index]?.EmergencyPersonName ? (
                    <small className="text-danger">
                      {errors.dynamicForm[index].EmergencyPersonName.message}
                    </small>
                  ) : null}
                </Col>

                <Col xs={24} md={24} className="mb-4">
                  <Controller
                    name={`dynamicForm.${index}.emergencyMobNumber`}
                    control={control}
                    render={({ field }) => {
                      const { ref, ...rest } = field
                      return (
                        <FloatInput label="Mobile Number">
                          <PatternFormat
                            format="(###) ###-####"
                            className={`formatted-mobile-input flex px-[11px] py-[12px] w-full  rounded-[6px] border border-${
                              errors.dynamicForm?.[index]?.emergencyMobNumber
                                ? "danger"
                                : isFocused?.[field.name]
                                ? "primary"
                                : "#dfdfdf"
                            } focus:outline-none`}
                            {...rest}
                            onChange={e => rest.onChange(handleMobileChange(e))}
                            onFocus={() => handleFocus(field.name)}
                            onBlur={() => handleBlur(field.name)}
                          />
                        </FloatInput>
                      )
                    }}
                  />
                  {errors.dynamicForm?.[index]?.emergencyMobNumber ? (
                    <small className="text-danger">
                      {errors.dynamicForm[index].emergencyMobNumber.message}
                    </small>
                  ) : null}
                </Col>

                <Col xs={24} md={24} className="mb-3">
                  <Controller
                    name={`dynamicForm.${index}.EmergencyPersonRelation`}
                    control={control}
                    render={({ field }) => (
                      <FloatInput label="Relationship">
                        <Select
                          status={
                            errors.dynamicForm?.[index]?.EmergencyPersonRelation
                              ? "error"
                              : undefined
                          }
                          className="w-full"
                          value={field.value}
                          onChange={value => {
                            field.onChange(value)
                            setFormData(prevData => ({
                              ...prevData,
                              [`dynamicForm[${index}].EmergencyPersonRelation`]: value
                            }))
                          }}>
                          {RelationshipOptions.map(option => (
                            <Select.Option key={option} value={option}>
                              {option}
                            </Select.Option>
                          ))}
                        </Select>
                      </FloatInput>
                    )}
                  />
                  {errors.dynamicForm?.[index]?.EmergencyPersonRelation ? (
                    <small className="text-danger">
                      {errors.dynamicForm[index].EmergencyPersonRelation.message}
                    </small>
                  ) : null}
                </Col>

                {updateDetailsApiErr?.[field?.contactId] ? (
                  <Col className="mb-2" xs={24}>
                    <p className="text-danger font-semibold">
                      {updateDetailsApiErr?.[field?.contactId]}
                    </p>
                  </Col>
                ) : null}
              </Row>
            ))}

            {watchForm?.length < 3 ? (
              <Button
                icon={<PlusCircleFilled />}
                className="ml-[-12px]"
                type="link"
                onClick={() => append(defaultValues)}
                disabled={
                  watchForm?.[watchForm?.length - 1]?.EmergencyPersonName &&
                  watchForm?.[watchForm?.length - 1]?.emergencyMobNumber &&
                  watchForm?.[watchForm?.length - 1]?.EmergencyPersonRelation
                    ? false
                    : true
                }>
                Add More Emergency Contact
              </Button>
            ) : null}

            {postNewDetailsApiErr || updateWelcomeError ? (
              <Col className="mb-4" xs={24}>
                <p className="text-danger font-semibold">
                  {postNewDetailsApiErr || updateWelcomeError}
                </p>
              </Col>
            ) : null}

            <StepsFooter
              handleBack={handleBack}
              nextBtnLoading={postNewDetailsLoading || updateLoading || updateWelcomeLoading}
              nextBtnText={
                watchForm?.[0]?.EmergencyPersonName &&
                watchForm?.[0]?.emergencyMobNumber &&
                watchForm?.[0]?.EmergencyPersonRelation
                  ? "Next"
                  : "Skip"
              }
            />
          </form>
        </>
      ) : (
        <Loading />
      )}
      {isCodeModalVisible ? (
        <EnrollmentSplCodeModal
          isCodeModalVisible={isCodeModalVisible}
          setIsCodeModalVisible={setIsCodeModalVisible}
          patientId={patientId}
        />
      ) : null}
    </>
  )
}
export default EmergencyContactDetailWeb
