import React from "react"
import { Modal, Button, Col, Divider } from "antd"
import { useNavigate } from "react-router-dom"

const PasswordSuccessModal = ({
  setChangePasswordShow,
  passSuccessModalVisible,
  setPassSuccessModalVisible
}) => {
  const navigate = useNavigate()
  const handleLogin = () => {
    setChangePasswordShow(false)
    setPassSuccessModalVisible(false)
    navigate("/login", { replace: true })
  }

  return (
    <Modal
      open={passSuccessModalVisible}
      centered
      footer={null}
      closable={false}
      className="text-center px-8">
      <div className="flex flex-col justify-center items-center ">
        <h2 className="text-2xl font-semibold my-3">Password created successfully</h2>
        <span className="text-base">
          Your password has been created,
          <br />
          kindly login.
        </span>
        <Divider />
        <Col>
          <Button
            size="large"
            block
            type="primary"
            onClick={handleLogin}
            className="min-w-36 h-12 rounded-xl flex justify-center items-center">
            Log in
          </Button>
        </Col>
      </div>
    </Modal>
  )
}

export default PasswordSuccessModal
