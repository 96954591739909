import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import PlanView from "@fitpeo/fit-plan-viewer"
import Loading from "../components/layouts/Loading/Loading"
import { TempAxiosGet } from "../utils/TokenStorage"
import { getDateOfService } from "../utils/getDateOfService"

const MobileCCM = () => {
  const [patientInfoDetail, setPatientInfoDetail] = useState(null)
  const [carePlanData, setCarePlanData] = useState()
  const [loading, setLoading] = useState(true)
  const [firstCall, setFirstCall] = useState(false)
  const [providers, setProviders] = useState(null)
  const [practitioners, setPractitioners] = useState(null)
  const [icdCodes, setIcdCodes] = useState(null)
  // const { isDark } = useSelector(state => state.themeConfig)

  // useEffect(() => {
  //     setLoading(true)
  //     const timer = setTimeout(() => {
  //         if (window.value !== undefined && window.token !== undefined && firstCall === false) {
  //                 //dispatch(getPatientInfo({ patientId: window.value.patientId }));
  //                 getPatientData(window.value.patientId)
  //                 getCcmPlan(window.value.patientId)
  //                 setFirstCall(true)
  //         }
  //     }, 1000);
  //     setLoading(false)
  //     return () => clearTimeout(timer);

  // }, [window.token]);

  // It will convert v1 careplan structure to v2 careplan structure
  const convertCareplanToV2 = plan => {
    if (!plan) return
    if (plan?.body) return plan?.body
    else return Object.values(plan)
  }


  useEffect(() => {
    setTimeout(() => {
      if (window.value !== undefined && window.token !== undefined && firstCall === false) {
        getPatientData(window.value.patientId)
        setFirstCall(true)
      }
    }, 1000)
  }, [window.token]) //eslint-disable-line

  const getCcmPlan = async patientID => {
    const res = await TempAxiosGet(
      window.token,
      `/api/ccm/careplan/comprehensive/latest/${patientID}/fetch`
    )
    if (res?.details) {
      setCarePlanData(res?.details)
    }

    if (!res?.details?.plan?.planIcds) getIcds(patientID)
    if (res?.details?.plan?.providers) {
      setProviders(res?.details?.plan?.providers)
      setPractitioners(res?.details?.plan?.practitioners)
    } else if (res?.details?.plan?.provider) {
      setProviders([`${res?.details?.plan?.provider?.providerFirstName && res?.details?.plan?.provider?.providerFirstName !== "" ? " " + res?.details?.plan?.provider?.providerFirstName : ""}
      ${res?.details?.plan?.provider?.providerMiddleName && res?.details?.plan?.provider?.providerMiddleName !== "" ? " " + res?.details?.plan?.provider?.providerMiddleName : ""} 
      ${res?.details?.plan?.provider?.providerLastName && res?.details?.plan?.provider?.providerLastName !== "" ? " " + res?.details?.plan?.provider?.providerLastName : ""}`])
    } else {
      getProvider(patientID)
    }
  }

  const getPatientData = async patientId => {
    const res = await TempAxiosGet(window.token, `/api/cs/patients/${patientId}/profile`)
    if (res?.status === 200) {
      setPatientInfoDetail(res?.details)
      await getCcmPlan(patientId)
      setLoading(false)
    }
  }

  const getIcds = async patientID => {
    const res = await TempAxiosGet(window.token, `/api/cs/patients/diagnostic/${patientID}/fetch`)
    setIcdCodes(res?.details?.map(({ icdCode }) => icdCode))
  }

  const getProvider = async patientID => {
    const res = await TempAxiosGet(window.token, `/api/cs/patients/monitoring/${patientID}/fetch`)
    let provider = []
    let practitioner = []

    if (res?.details?.ccm?.[0]) {
      provider = res?.details?.ccm?.[0]?.providerId ? [` ${res?.details?.ccm?.[0]?.providerfirstName}
      ${res?.details?.ccm?.[0]?.providerMiddleName && res?.details?.ccm?.[0]?.providerMiddleName !== "" ? " " + res?.details?.ccm?.[0]?.providerMiddleName : ""} 
      ${res?.details?.ccm?.[0]?.providerLastName}`] : []
    }
    if (res?.details?.ccm?.[0]) {
      practitioner = res?.details?.ccm?.[0]?.practitionerId ? [` ${res?.details?.ccm?.[0]?.practitionerfirstName}
      ${res?.details?.ccm?.[0]?.practitionerMiddleName && res?.details?.ccm?.[0]?.practitionerMiddleName !== "" ? " " + res?.details?.ccm?.[0]?.practitionerMiddleName : ""} 
      ${res?.details?.ccm?.[0]?.practitionerLastName}`] : []
    }

    setProviders(provider)
    setPractitioners(practitioner)
  }

  return (
    <>
      <Helmet>
        <script>{`
                function fillDetails(jsonData) {
                var content = JSON.parse(decodeURIComponent(jsonData))
                // var content = {"token":"eyJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJzZWxmIiwic3ViIjoiOGU5ZDhiMjItZTQ4Ny00MjI2LTgyOWQtMzAwOWQ3Yzk4YWYxIiwiZXhwIjoxNzE3NjczNjA4LCJpYXQiOjE3MTc2NzAwMDgsInNjb3BlIjoicGF0aWVudCJ9.MOX0WrsbaYojyFK8Xh0DQ3MLn4qZMmmO1yGHYwQB3lCKjpSwTXFYvjWj-fAvQhGK5v78H4G4KGHhqAbyi9mPGk8RBJVubmECTqJ2eHQ81k-gCRrclSnkpooWoGSHr6fnkfDzjbfTvOZxPgLNNnrX5dyxCdbM8VRRPLMLS0gcDXyi597txjy9sO5f3BaGffmh-Brgw0SCghnNdAKMp-WBFxxB9VSwcxQBd-8TatCP8tqgXN03mMGpjCyXQsoghBKkElwTBUJOO9EZqJDbmk4JRQXa-JaUp5r1xTa3hWtnlrjrtnzocuyBI44cqREXrpGGJ0rlgYlgMtaztWoTe_wdfQ", patientId:"8e9d8b22-e487-4226-829d-3009d7c98af1"};
                window.isMobileVersion = true;
                window.value = content;
                window.token = content.token;
              }`}</script>
      </Helmet>
      {loading & !carePlanData ? (
        <Loading />
      ) : (
        <PlanView
          type="ccm"
          planDate={carePlanData?.planDate}
          providers={providers}
          practitioners={practitioners}
          patientInfoDetail={patientInfoDetail}
          plan={convertCareplanToV2(carePlanData && carePlanData?.plan)}
          planIcds={carePlanData?.plan?.planIcds ?? icdCodes}
          addedBy={carePlanData?.plan?.addedBy}
          isDarkMode={false}
          userType="patient"
          serviceDate={getDateOfService(carePlanData?.planDate)}
        />
      )}
    </>
  )
}

export default MobileCCM
