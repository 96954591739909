import React, { useEffect, useState } from "react"
import { Row, Col, Button, Input, Checkbox, Radio } from "antd"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller, FormProvider } from "react-hook-form"
import * as yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import {
  getEquipmentStatusAction,
  postEquipmentMethodStatusAction
} from "../../../redux/actions/equipmentStatusAction"
import Loading from "../../layouts/Loading/Loading"
import { EquipmentSatusOptions } from "../Types"

const EquipmentShipOption = ({ patientId, setCurrentStep }) => {
  const { error: postError, loading: postLoading } = useSelector(
    state => state.postEquipmentMethodOption
  )
  const {
    getEquipmentStatus,
    loading: equipmentStatusLoading,
    error: equipmentStatusError
  } = useSelector(state => state.getEquipmentStatus)

  const schema = yup.object({
    status: yup.string().required("Please select any one option")
  })
  const dispatch = useDispatch()

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
        status: getEquipmentStatus?.status ?? ""
    }
  })

  useEffect(() => {
    dispatch(getEquipmentStatusAction(patientId))
  }, [])

  useEffect(() => {
    setValue("status",EquipmentSatusOptions.find(
        equipment =>
          equipment.label.toLowerCase().trim() ===
          getEquipmentStatus?.status?.toLowerCase()?.trim()
      )?.value || "")
  }, [equipmentStatusLoading])

  const {
    reset,
    control,
    watch,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors }
  } = methods

  const onSubmit = async data => {
    const result = dispatch(postEquipmentMethodStatusAction(patientId, data))
    result.then(res => {
      if (res?.status === 200 || res?.status === 201) {
        setCurrentStep(prev => prev + 1)
        dispatch(getEquipmentStatusAction(patientId))
      }
    })
  }

  const prevPage = () => {
    setCurrentStep(prev => prev - 1)
  }
  window.back = () => {
    prevPage()
  }
  return (
    <>
      {!equipmentStatusLoading ? (
        <>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} className="h-[90vh] relative">
            <Row gutter={20} className="px-3">
              <h1
                className={`pt-4 text-nowrap ${
                  !window?.isMobileVersion ? "pb-1" : "pb-8"
                }`}>
                How do you want to pick up the device(s)?
                <span className="text-danger">*</span>
              </h1>
              <Col xs={24} md={12} className="mb-4">
                
                <Controller
                  name="status"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Radio.Group {...field} className="block" >
                      <Radio value="will_pick_up_from_clinic" className="mt-2 w-full">
                        I want to pick up device(s) from the clinic
                      </Radio>
                      <Radio value="delivery_requested" className="my-1">
                        I want to get the device(s) shipped
                      </Radio>
                    </Radio.Group>
                  )}
                />
                {errors.status ? (
                  <small className="text-danger block">{errors.status.message}</small>
                ) : null}
              </Col>
              {postError ? (
                <Col className="mb-4" xs={24}>
                  <p className="text-danger font-semibold">{postError}</p>
                </Col>
              ) : null}
               </Row> 

              <Row className="w-full mt-2 absolute bottom-1">
              <Col xs={12} md={12} className="mb-2 pr-2 pl-0">
                <Button
                  type="primary"
                  onClick={prevPage}
                  style={{ minWidth: 0 }}
                  className="w-full">
                  Back
                </Button>
              </Col>
              <Col xs={12} md={12} className="mb-2 pr-0 pl-2">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={postLoading}
                  style={{ minWidth: 0 }}
                  className="w-full">
                  Next
                </Button>
              </Col>
            </Row>
            </form>
          </FormProvider>
        </>
      ) : (
        <Loading />
      )}
    </>
  )
}

export default EquipmentShipOption
