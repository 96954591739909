import moment from 'moment';

// Function to check if a year is a leap year
const isLeapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

// Utility function to format the date based on conditions
export const getDateOfService = (planDate) => {
  if (!planDate) return ''; // Handle case where planDate is not defined

  const endOfMonth = moment(planDate).endOf('month');
  
  if (moment(planDate).month() === 1) { // February
    const year = moment(planDate).year();
    const leapYearAdjustment = isLeapYear(year) ? 1 : 2;
    return endOfMonth.add(leapYearAdjustment, 'days').add(1, 'days').format('MM/DD/YYYY');
  } else {
    return endOfMonth.format('MM/DD/YYYY');
  }
};