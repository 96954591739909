// Field configuration
const fields = [
  {
    name: "street",
    label: "Street",
    placeholder: "Enter Street",
    isRequired: true
  },
  {
    name: "apartmentNumber",
    label: "Apartment Number (optional)",
    placeholder: "Enter Apartment Number",
    maxLength: 5,
    isRequired: false
  },
  {
    name: "city",
    label: "City",
    placeholder: "Enter City",
    isRequired: true
  },
  {
    name: "state",
    label: "State",
    placeholder: "Enter State",
    isRequired: true
  },
  {
    name: "zipcode",
    label: "Zipcode",
    placeholder: "Enter Zipcode",
    type: "number",
    isRequired: true
  }
]

export default fields
