import React, { useEffect, useState } from "react"
import { Steps } from "antd"
import FitPeoLogo from "../../../../assets/fitpeo_logo.png"
import { useSelector } from "react-redux"
import PersonalDetailFormWeb from "./PersonalDetailFormWeb"
import VitalDetailWeb from "./VitalDetailWeb"
import EmergencyContactDetailWeb from "./EmergencyContactDetailWeb"
import DeviceDeliveryWeb from "./DeviceDeliveryWeb"

const header_height = 80

const DetailsStepper = ({ prevPage, patientId, showDeviceDelivery }) => {
  const { patientVitals, loading: vitalsLoading } = useSelector(state => state.patientVitals)
  const { loading: patientLoading, userInfo: patientInfoDetail } = useSelector(
    state => state.userInfo
  )

  const examplePersonalData = {
    id: patientId,
    firstName: patientInfoDetail?.firstName,
    lastName: patientInfoDetail?.lastName,
    middleName: patientInfoDetail?.middleName ?? null,
    email: patientInfoDetail?.email,
    mobileNumber: patientInfoDetail?.mobileNumber,
    gender: patientInfoDetail?.gender,
    dob: patientInfoDetail?.dob,
    height: patientVitals?.find(item => item.vitalType === "height")?.value,
    weight: patientVitals?.find(item => item.vitalType === "weight")?.value,
    telephone: patientInfoDetail?.telephone,
    healthcareProvider: patientInfoDetail?.orgName,
    ...patientInfoDetail
  }
  const [currentStep, setCurrentStep] = useState(0)
  const [formData, setFormData] = useState(examplePersonalData)

  useEffect(() => {
    if (patientVitals?.length) {
      setFormData({
        ...formData,
        height: patientVitals?.find(item => item.vitalType === "height")?.value,
        weight: patientVitals?.find(item => item.vitalType === "weight")?.value
      })
      if (patientInfoDetail?.length) {
        setFormData({
          ...formData,
          firstName: patientInfoDetail?.firstName,
          lastName: patientInfoDetail?.lastName,
          middleName: patientInfoDetail?.middleName ?? null,
          email: patientInfoDetail?.email,
          mobileNumber: patientInfoDetail?.mobileNumber,
          gender: patientInfoDetail?.gender,
          dob: patientInfoDetail?.dob,
          telephone: patientInfoDetail?.telephone,
          teleExtension: patientInfoDetail?.extension
        })
      }
    }
  }, [vitalsLoading, patientInfoDetail])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentStep])

  const handleBack = () => {
    if (currentStep <= 0) prevPage()
    else setCurrentStep(currentStep - 1)
  }

  const steps = [
    {
      title: "Personal Details",
      content: (
        <PersonalDetailFormWeb
          formData={formData}
          setFormData={setFormData}
          setCurrentStep={setCurrentStep}
          handleBack={handleBack}
        />
      )
    },
    {
      title: "Vital Details",
      content: (
        <VitalDetailWeb
          formData={formData}
          setFormData={setFormData}
          setCurrentStep={setCurrentStep}
          handleBack={handleBack}
        />
      )
    },
    {
      title: "Emergency Contact Details",
      content: (
        <EmergencyContactDetailWeb
          patientId={formData?.id}
          setFormData={setFormData}
          setCurrentStep={setCurrentStep}
          handleBack={handleBack}
        />
      )
    }
  ]

  if (showDeviceDelivery) {
    steps.push({
      title: "Device Delivery",
      content: (
        <DeviceDeliveryWeb
          formData={formData}
          setFormData={setFormData}
          setCurrentStep={setCurrentStep}
        />
      )
    })
  }

  const items = steps.map(item => ({
    key: item.title,
    title: <span className="text-xs">{item.title}</span>
  }))

  return (
    <>
      <div
        style={{ height: header_height }}
        className="px-10 border-bottom shadow-md flex items-center">
        <img src={FitPeoLogo} width={90} alt="fitpeo logo" />
      </div>
      <div style={{ height: `calc(100vh - ${header_height}px)` }} className="pt-3 overflow-auto">
        <div className="w-[75%] m-auto">
          <Steps size="small" labelPlacement="vertical" current={currentStep} items={items} />
          <div className="px-3 details relative">{steps[currentStep].content}</div>
        </div>
      </div>
    </>
  )
}

export default DetailsStepper
