import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import { Input, Button } from "antd"
import { useToasts } from "react-toast-notifications"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useDispatch } from "react-redux"
import { passwordUpdateAction, userLogoutAction } from "../../redux/actions/userActions"
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Mixpanel } from "../../utils/mixpanel"
import FloatInput from "../../utils/FloatInput/FloatInput"
import PasswordValidation, {
  isMin8Chars,
  isMinOneNum,
  isMinOneSplChar
} from "../../utils/PasswordValidations"
import PasswordSuccessModal from "./PasswordSuccessModal"

const INVALID_CODE = "Invalid Code"

const schema = yup
  .object({
    otp: yup.string().required(INVALID_CODE),
    newPassword: yup.string().required("Invalid Password"),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match")
  })
  .required()

const ChangePasswordForm = ({ otp, userName, setChangePasswordShow }) => {

  const [passSuccessModalVisible, setPassSuccessModalVisible] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { addToast } = useToasts()

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch
  } = useForm({
    defaultValues: {
      otp,
      newPassword: "",
      confirmPassword: ""
    },
    resolver: yupResolver(schema)
  })

  const newPasswordValue = watch("newPassword")

  const onSubmit = async ({ otp, newPassword }) => {
    const result = await dispatch(passwordUpdateAction(userName, otp, newPassword))
    if (result?.message === "Success") {
      setPassSuccessModalVisible(true)     
      // dispatch(userLogoutAction())
    } else {
      addToast("Password Reset Failed", {
        appearance: "error",
        autoDismiss: true
      })
      addToast(INVALID_CODE, {
        appearance: "error",
        autoDismiss: true
      })
    }
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <Controller
            name="newPassword"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FloatInput label="New Password">
                <Input.Password
                  required
                  size="large"
                  type="password"
                  status={errors.newPassword ? "error" : undefined}
                  {...field}
                  onChange={e => field.onChange(e?.target?.value?.replace(/\s/g, ""))}
                  iconRender={visible => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)}
                />
              </FloatInput>
            )}
          />
          <PasswordValidation password={newPasswordValue} />
        </div>
        <div className="mb-4">
          <Controller
            control={control}
            name="confirmPassword"
            rules={{ required: true }}
            render={({ field }) => (
              <FloatInput label="Confirm New Password">
                <Input.Password
                  required
                  size="large"
                  type="password"
                  status={errors.confirmPassword ? "error" : undefined}
                  {...field}
                  onChange={e => field.onChange(e?.target?.value?.replace(/\s/g, ""))}
                  iconRender={visible => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)}
                />
              </FloatInput>
            )}
          />
          {errors.confirmPassword ? (
            <small className="text-danger">{errors.confirmPassword.message}</small>
          ) : null}
        </div>
        <Button
          block
          size="large"
          type="primary"
          htmlType="submit"
          loading={isSubmitting}
          disabled={
            !isMin8Chars(newPasswordValue) ||
            !isMinOneSplChar(newPasswordValue) ||
            !isMinOneNum(newPasswordValue)
          }
          onClick={() => {
            Mixpanel.track("Change Password Clicked")
          }}>
          Continue
        </Button>
      </form>
      <PasswordSuccessModal
        setChangePasswordShow={setChangePasswordShow}
        passSuccessModalVisible={passSuccessModalVisible}
        setPassSuccessModalVisible={setPassSuccessModalVisible}
      />
    </>
  )
}

export default ChangePasswordForm
