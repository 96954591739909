import React from "react"

import "./FloatInput.css"

const FloatInput = ({ children, label, placeholder, required }) => {
  const childrenProps = children?.props

  if (!placeholder) placeholder = label

  const isOccupied =
    childrenProps?.value !== null &&
    childrenProps?.value !== undefined &&
    childrenProps?.value !== "" &&
    childrenProps?.value.length !== 0

  const labelClass =
    childrenProps?.disabled || isOccupied ? "label as-label" : "label as-placeholder"

  const requiredMark =
    required || childrenProps?.required ? <span className="text-danger">*</span> : null

  return (
    <div className="float-label">
      {children}
      <label className={labelClass}>
        {childrenProps?.disabled ? (
          label
        ) : isOccupied ? (
          label
        ) : (
          <span className={childrenProps.prefix && "ml-5"}>{placeholder}</span>
        )}{" "}
        {requiredMark}
      </label>
    </div>
  )
}

export default FloatInput
