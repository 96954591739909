import React, { useState } from "react"

import { Input, Button, Typography } from "antd"
import { useToasts } from "react-toast-notifications"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { RiLockPasswordLine } from "react-icons/ri"
import OTPBgImage from "../../assets/otp-screen-bg.png"
import fitpeoLogo from "../../assets/fitpeo_logo.png"

import { useDispatch } from "react-redux"
import { forgetPasswordProviderAction } from "../../redux/actions/utilsActions"
import { useNavigate } from "react-router-dom"
import { Mixpanel } from "../../utils/mixpanel"

const schema = yup
  .object({
    credential: yup
      .string()
      .required("Enter a Valid Email or Phone Number")
      .test("value-test", "Enter a Valid Email or Phone Number", function (value) {
        const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/

        const phoneRegex = /^\(?[2-9]\d{2}\)?[-.]?\d{3}[-.]?\d{4}$/
        let isValidEmail = emailRegex.test(value)
        let isValidPhone = phoneRegex.test(value)
        if (!isValidEmail && !isValidPhone) {
          return false
        }
        return true
      })
  })
  .required()

const defaultValues = {
  credential: ""
}

const CODE_SENT_SUCCESS_MSG = "Code Sent Successfully"

const OTPForm = ({ setShowNext, setCredential, setUserName }) => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const [error, setError] = useState(null)
  const [codeErrors, setCodeErrors] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })
  const credentialWatcher = watch("credential")

  const onSubmit = async ({ credential }) => {
    setError(null)
    setLoading(true)
    const result = await dispatch(forgetPasswordProviderAction(credential))
    if (result?.message === "Success") {
      setShowNext(true)
      setUserName(credential)
      setCredential(credential)
      addToast(CODE_SENT_SUCCESS_MSG, {
        appearance: "success",
        autoDismiss: true
      })
    } else {
      setCodeErrors(result)
      // addToast("OTP Sent Failed", {
      //   appearance: "error",
      //   autoDismiss: true
      // })
    }
    setLoading(false)
  }

  return (
    <div
      className="otp-wrapper-img flex max-md:justify-center max-md:px-2 h-screen bg-cover bg-right w-full justify-start items-center"
      style={{ backgroundImage: `url(${OTPBgImage})` }}>
      <img
        alt="logo"
        src={fitpeoLogo}
        className="absolute top-6 md:top-10 md:right-10 w-[10vw]"
      />
      <div className="sm:mx-1 p-8 max-md:mt-44 md:ml-28 max-w-[500px] flex flex-col items-center justify-center rounded-xl border-[2px] border-[rgba(88,130,193,0.49)] backdrop-blur-xl bg-[rgba(255,255,255,0.67)] shadow-lg">
        <div className="bg-primary rounded-full p-3 mb-3 text-center">
          <RiLockPasswordLine size={40} className="text-white" />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          {!loading && codeErrors?.message && codeErrors?.message !== "" && (
            <div
              style={{
                backgroundColor: "rgba(255, 59, 48, 0.06)",
                borderLeft: "2px solid rgba(255, 59, 48, .6)"
              }}
              className="px-3 py-2 my-4">
              <p>
                <strong>Note: </strong>
                {codeErrors?.message}
              </p>
            </div>
          )}
          <div className="mb-4">
            <label className="mb-2" htmlFor="input-credential">
              Email or Phone Number
            </label>
            <Controller
              name="credential"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-credential"
                  status={errors.credential ? "error" : undefined}
                  {...field}
                  onChange={e => field.onChange(e?.target?.value?.replace(/\s/g, ""))}
                />
              )}
            />
            {errors.credential ? (
              <small className="text-danger">{errors.credential.message}</small>
            ) : null}
          </div>
          <Button
            block
            size="large"
            type="primary"
            htmlType="submit"
            disabled={isSubmitting || !credentialWatcher?.length || codeErrors?.message?.includes("You have exceeded")}
            onClick={() => Mixpanel.track("Request OTP")}>
            Send Code
          </Button>
          <div className="mt-4">
            <small
              className="text-center cursor-pointer text-secondary"
              onClick={() => navigate("/login")}>
              Back to Login
            </small>
          </div>

          {error !== null && typeof error === "string" && (
            <p className="text-danger text-center font-medium mt-4">{error}</p>
          )}

          {error !== null &&
            typeof error === "object" &&
            Object.values(error)?.map((value, index) => (
              <p key={index} className="font-bold text-danger text-center mt-4">
                <small>{value}</small>
              </p>
            ))}
        </form>
      </div>
    </div>
  )
}

export default OTPForm
