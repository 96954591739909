import { Button, Modal } from "antd"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { LeftOutlined, RightOutlined } from "@ant-design/icons"

// Custom arrow components
export const NextArrow = ({ onClick }) => (
  <div
    className="absolute bottom-9 right-1 p-[5px] cursor-pointer z-50 text-[#656565] hover:text-[#212121]"
    style={{ fontSize: "14px", lineHeight: "1" }}
    onClick={onClick}>
    <RightOutlined />
  </div>
)

export const PrevArrow = ({ onClick }) => (
  <div
    className="absolute bottom-9 left-1 p-[5px] cursor-pointer z-50 text-[#656565] hover:text-[#212121]"
    style={{ fontSize: "14px", lineHeight: "1" }}
    onClick={onClick}>
    <LeftOutlined />
  </div>
)

const ImageCarouselWeb = ({ next, open, closeModal }) => {
  const { data: userWelcomeTutorial, error } = useSelector(state => state.getSelfOnboardedPatient)

  const [welcomeImages, setWelcomeImages] = useState([])
  const [currentImageIdx, setCurrentImageIdx] = useState(0)

  useEffect(() => {
    if (userWelcomeTutorial) {
      const images = Object.values(userWelcomeTutorial?.tutorials)
      setWelcomeImages(images)
    }
  }, [userWelcomeTutorial])

  const handleSkip = () => {
    closeModal()
    next()
  }

  const handlePrev = () => setCurrentImageIdx(currentImageIdx - 1)

  const handleNext = () => setCurrentImageIdx(currentImageIdx + 1)

  return (
    <Modal
      open={open}
      centered
      footer={null}
      closable={false}
      className="image-carausel-modal relative"
      destroyOnClose>
      {currentImageIdx === 0 ? (
        <NextArrow onClick={handleNext} />
      ) : (
        <PrevArrow onClick={handlePrev} />
      )}
      <img style={{ height: "calc(100vh - 150px)" }} src={welcomeImages[currentImageIdx]} />
      <div className="flex justify-center">
        <Button
          type="primary"
          className="w-[60px] xl:w-[120px] mt-[-70px]"
          style={{ boxShadow: "0 0 10px 5px rgba(0,0,0,0.4)" }}
          onClick={handleSkip}>
          {currentImageIdx === welcomeImages?.length - 1 ? "Next" : "Skip"}
        </Button>
      </div>
    </Modal>
  )
}
export default ImageCarouselWeb
